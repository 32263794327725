

.visor__layout--captura .BrainhubCarousel__container {
  display: none;
}
.visor__layout--captura .perfil__datos {
  font-size: 18px;
}
.visor__layout--captura .perfil__sizer {
  margin-top: 40px;
}
