

.mapa-modal-pdi {

}

.pdi-servicio__title {
    font-size: 1.2em;
    font-weight: bold;
}
.pdi-servicio__list {
    margin: .5em 0;
    padding: 0;
}
.pdi-servicio {
    list-style: none;
    text-align: left;
    margin-bottom: .5em;
    display: flex;
    flex-direction: row;
}
.pdi-servicio__icon {
    height: 2.5em;
    width: 2.5em;
    margin-right: .5em;
    border-radius: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.pdi-servicio__nombre {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-pdi__altitud {
  font-size: 1.3rem;
}

.modal-pdi__servicios {
  display: flex;
}

.modal-pdi__descripcion {
  text-transform: none;
}

.modal-pdi__imagen > img {
  max-width: 100%;
  max-height: 70vh;
}

.modal-pdi__avatar-user {
  width: 3em;
  max-height: 3em;
  object-fit: cover;
  border: 3px solid #FFFFFF;
  background-color: #FFFFFF;
}
