.obras__cerrar {
  right: 1rem;
  top: 1rem;
}

.obras__img {
  width: 25%;
}

@media (max-width: 577px) {
  .obras__img {
    width: 75%;
  }
}
