.container-tabs-sectores-pdis{
  display: inline-flex;
  margin: 8px 0;
}

.container-tabs-sectores-pdis .nav-link {
  border-radius: 4px;
  padding: 0.2rem 0.8rem;
}

a.tab-sectores-pdis {
  background-color: var(--color_fondo);
  color: var(--color_fondo_secundario);
  font-weight: bold;
}

.tab-sectores-pdis.active {
  background-color: var(--color_fondo_secundario);
  color: var(--color_fondo);
}
