

/* Transformamos un control posicionado en bottomleft en bottom */
.leaflet-left.leaflet-bottom {
    right: 0;
    display: flex;
    background: rgba(255, 255, 255, .0);
    transition: all 0.7s linear;
}
.leaflet-left.leaflet-bottom .leaflet-control {
    width: 100%;
    text-align: center;
    /*
    height: 100%;
    display: grid;
    grid-template-rows: 1fr auto;
    */
    display: flex;
    align-self: flex-end;
    flex-direction: column;
    margin: 0;
}


.control-expanded {
    /*
    right: 0;
    top: 0;
    */
    height: 100%;
    background: rgba(255, 255, 255, .8);
}

.leaflet-left.leaflet-bottom.control-expanded {
    /*
    right: 0;
    top: 0;
    */
    height: 100%;
    background: rgba(255, 255, 255, .8);
}

.control-expanded .leaflet-control {
    height: 100%;
    position: absolute;
}

.mapa-modal__wrapper {

}
.control-expanded .mapa-modal__wrapper {
    display: flex;
    /* padding: 2em; */
    /*
    align-self: center;
    justify-items: center;
    align-items: center;
    flex-grow: 1;
    */
    flex: 1;
    min-height: 0px;  /* firefox */
}

.mapa-botones {
    display: inline-block;
    align-self: flex-end;
    flex: none;
    width: 100%;
}
.control-expanded .mapa-botones {

}
    /*
    .control-expanded .mapa-modal__wrapper {
        display: flex;
        align-self: center;
    }
    */

.oculto {
    display: none;
}





/* Transformamos un control posicionado en topright en top */
.leaflet-right.leaflet-top {
    right: 0;
    display: flex;
    left: 0;
    /* height: 100%; */
}
.leaflet-right.leaflet-top .leaflet-control {
    display: grid;
    grid-template-columns: auto 1fr auto;
    width: 100%;
}

/* moveos o control de zoom...*/
.leaflet-top .leaflet-control.leaflet-control-zoom {
    margin-top: 60px;
}

.leaflet-container .mapa-paginador__left, .leaflet-container .mapa-paginador__right,
.leaflet-container .mapa-paginador__left:hover, .leaflet-container .mapa-paginador__right:hover,
.leaflet-container .mapa-paginador__left:active, .leaflet-container .mapa-paginador__right:active,
.leaflet-container .mapa-paginador__left:focus, .leaflet-container .mapa-paginador__right:focus {
    border: none;
    background: none;
    color: var(--color-principal);
    font-size: 60px;
    font-weight: bold;
    line-height: 30px;
    text-decoration: none;
    -webkit-text-stroke-width: 1.4px;
    -webkit-text-stroke-color: var(--color-secundario);
    margin-top: 5px;
    /* text-shadow: 0 0 5px black; /* para ue se vexan un pouquiño mellor */
}

.leaflet-container .mapa-paginador__left{
    margin-left: 10px;
}

.mapa-paginador__paginas {
    text-align: center;
    list-style: none;
    padding-left: 0px;
    margin-top: 5px;
}
.mapa-paginador__pagina-li {
    display: inline-block;

}
.mapa-paginador__pagina {
    border-radius: 100%;
    display: inline-block;
    overflow: hidden;
    margin: 5px;
    width: 15px;
    height: 15px;
    background: var(--color-secundario);
    color: var(--color-secundario) !important;
    /* box-shadow: 0 0 5px black; /* para ue se vexan un pouquiño mellor */
    border: 2px solid var(--color-terciario);
}
.mapa-paginador__pagina:before {
    content: " ";
}


.mapa-paginador__pagina--activa {
    width: 25px;
    height: 25px;
    color: var(--color-principal) !important;
    background: var(--color-principal);
    margin-bottom: 0;
    border: 2px solid var(--color-secundario);
}

.mapa-fullscreen > .leaflet-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}


/** Botons do visor **/

.boton-visor {
  font-family: 'Barlow Semi Condensed', Roboto, Arial, 'sans-serif';
  width: 57px;
  height: 57px;
  display: inline-block;
  background-color: var(--color-principal);
  border-radius: 5px !important;
  text-align: center;
  margin: 15px;
  overflow: hidden;
  padding: 0;
  transition: background-color 0.3s ease;
}

.boton-visor:hover,
.boton-visor:active,
.boton-visor:focus{
  background-color: var(--color-terciario);
  border: 1px solid var(--color-secundario);
}

.boton-visor a {
  color: var(--color_fondo)
}
.boton-visor button,
.boton-visor > a {
  color: var(--color_fondo);
  background-color: transparent;
  border: none;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.boton-visor .fas {
  margin-bottom: 5px;
  display: block;
}

.boton-visor > a:hover,
.boton-visor > a:active,
.boton-visor > a:focus{
  text-decoration: none;
}

.boton-visor.boton-destacado{
  background-color: var(--color-secundario);
  color: var(--color-principal);
  border: 1px solid var(--color-principal);
  transition: all 0.3s ease;
}

.boton-visor.boton-destacado:hover,
.boton-visor.boton-destacado:active,
.boton-visor.boton-destacado:focus{
  background-color: var(--color-terciario);
  color: var(--color-secundario);
  border: 1px solid var(--color-principal);
}

.boton-visor.boton-destacado:hover a,
.boton-visor.boton-destacado:active a,
.boton-visor.boton-destacado:focus a{
  color: var(--color-secundario);
}


.boton-visor.boton-visor--activo {
  background-color: var(--color-terciario);
  color: var(--color-secundario);
  border: 1px solid var(--color-principal);
}

.boton-visor.boton-destacado a{
  color: var(--color-principal);
}

.boton-visor .btn__texto {
  text-transform: uppercase;
  font-size: 10px;
  line-height: 1;
  display: block;
}

.boton-visor--small {
  width: 52px;
  height: 52px;
  margin: 6px 6px;
  display: table-cell;
  vertical-align: middle;
  font-size: 1.15rem;
  padding: 2px;
}

.boton-visor--small .btn__texto {
  text-transform: uppercase;
  font-size:  0.5em;
  line-height: 1;
}

@media (max-width: 350px) {
  .boton-visor--small {
    width: 45px;
    height: 45px;
    margin: 5px 5px;
  }
  .boton-visor--small .btn__texto {
    font-size:  0.4em;
  }
}

@media (min-width: 1024px) {
  .boton-visor--small {
    width: 80px;
    height: 80px;
    font-size: 1.7rem;
  }
}

@media (min-width: 1900px) {
  .boton-visor--small {
    width: 95px;
    height: 95px;
    font-size: 2rem;
    padding: 5px;
  }
}

.boton-visor__group {
  padding: 0;
  position: center;
}

ul.boton-visor__group {
  margin-bottom: 0.8rem;
}

.sub-heramientas ul.boton-visor__group {
  margin-bottom: 0;
}

.sub-heramientas .boton-visor--small {
  margin-bottom: 0;
}

.sub-heramientas .boton-visor{
  background-color: var(--color-secundario);
  color: var(--color-principal);
}

.sub-heramientas .boton-visor:hover{
  background-color: var(--color-terciario);
}

.sub-heramientas .boton-visor button,
.sub-heramientas .boton-visor a{
  color: var(--color-principal);
}

.sub-heramientas .boton-visor:hover button,
.sub-heramientas .boton-visor:hover a{
  color: var(--color-secundario);
}

.boton-visor .fa-stack {
  font-size: 0.6em;
  margin-top: -0.5em;
}

.boton-visor .fa-stack-2x {
  opacity: 0.7;
}

.boton-visor .boton-visor__icon-texto {
  margin-top: -.2em;
  font-size: .9em;
  text-transform: none;
  font-style: normal;
  font-weight: bold;
}

/** /Botons do visor **/
