.videoportada iframe{
  max-width: 100%;
}

.videoportada_titulo {
  line-height: 1.1em;
  margin-bottom: 0.8em;
}

.videoportada_image {
  position:relative;
  overflow:hidden;
  padding-bottom:100%;
}

.videoportada_image img{
  position: absolute;
  left: 50%;
  max-height: 150px;
  transform: translateX(-50%);
}

.videoportada_lista {
  max-height: 607px;
  overflow: auto;
}

.videoportada-activo {
  background-color: #ffffff;
}

@media (max-width: 1199.98px) {
  .videoportada iframe {
    width: 290px !important;
    height: 517px !important;
  }
  .videoportada_lista {
    max-height: 517px;
  }
}

@media (max-width: 575.98px) {
  .videoportada iframe{
    width: 90vw !important;
    height: 142vw !important;
  }
  .videoportada_lista {
    max-height: unset;
  }
  .videoportada p {
    line-height: 1.2em;
    font-size: 0.9em;
  }
}
