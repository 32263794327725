
.esquema {
    width: 100%;
    max-width: 100%;
    margin-bottom: 2em;
}

.esquema__body {
    padding: 20px 0 10px;
    display: block;
}

.esquema__sector-titulo {
  padding: 3px;
  border-radius: 6px;
}

.esquema__color {
    text-align: left;
}
.esquema__color > span {
}
.esquema__badge {
  width: 3em;
    /*height: 0.6em;*/
  border-radius: 15%;
  display: inline-block;
  font-size: .75em;
  text-align: center;
  font-weight: bold;
}
.esquema__icono {
  display: flex;
  width: 20px;
  justify-content: center;
  align-items: center;
}
.esquema__icono svg {
    fill: #FFFFFF;
    max-height: 17px;
}
.esquema__rep {
    width: 2em;
}
.esquema__nombre {
    text-align: left;
    line-height: 1em;
    padding-top: 3px;
    padding-bottom: 3px;
}
.esquema__rep2 {
    text-align: right;
    width: 2em;
}
.esquema__dist {
    text-align: right;
    min-width: 21%;
}

.esquema__foot {
    border-top: 1px dashed white;
}
.esquema__final-text {
    text-align: left;
    font-weight: bold;
}
.esquema__final-dist {
    text-align: right;
    font-weight: bold;
}

.esquema__sector-info {
  line-height: 1em;
  font-size: 0.8em;
}

.ul-esquema {
    list-style: none;
}

.ul-esquema li {
    text-align: left;
}

.esquema__totales {
  text-transform: initial;
  white-space: pre-line;
}

.esquema__deporte {
  background-color: var(--color-principal);
}

.esquema__deporte svg{
  width: 40px;
  fill: var(--color-secundario);
  stroke: var(--color-secundario);
  stroke-width: 0;
}

.esquema__deporte svg path {
  fill: var(--color-secundario);
  stroke: var(--color-secundario);
  stroke-width: 0;
}

.esquema__totales--altitudes {
  background-color: var(--color-principal);
  color: var(--color-secundario);
}

.esquema__div {
  cursor: pointer;
}

.esquema__foot-simple {
  border-top: initial;
}
