.mapa-submodal__lista--pdis,
.mapa-submodal__lista--pdis button {
  color: var(--color-terciario);
}

.mapa-submodal__lista--pdis button.btn-pdi-recorrido {
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 1.05em;
  padding: .75rem 1.25rem;
}

.mapa-submodal__lista--pdis button.btn-pdi-recorrido > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  line-height: 1em;
}

.distancia-pdi {
  float: right;
  display: inline-flex;
}

.lista-servicios-pdis {
  display: inline-flex;
  padding: 0 10px;
}

.icono-servicio {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px 1px;
  margin: 2px;
  height: 26px;
  width: 26px;
}

.icono-servicio > i {
  font-size: 16px;
}

.icono-mas-servicio {
  background-color: var(--color_fondo_secundario);
  color: var(--color_fondo)
}

.nombre-pdi {
  display: flex;
  line-height: 30px;
}

button.mapa-submodal__btn-visibilidad {
  color: var(--color-secundario);
}

.mapa-submodal__btn-visibilidad:focus,
.mapa-submodal__btn-visibilidad:active {
  outline: none;
}
