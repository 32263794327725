

.modal-cambiar-vista {
    list-style: none;
    margin: 1.2em 0;
    padding: 0;
}

.modal-cambiar-vista__item {
    text-align: left;
    margin-bottom: 0px;
    padding-bottom: 2px;
    border-bottom: 1px dashed var(--color-terciario);
}

.modal-cambiar-vista__item.seleccionada .modal-cambiar-vista__btn{
    font-weight: 800;
    color: var(--color-terciario);
    cursor: default;
}

.modal-cambiar-vista__item.seleccionada .modal-cambiar-vista__btn::before,
.modal-cambiar-vista__item.seleccionada .modal-cambiar-vista__btn::after {
    content: "\f0a5";
    padding-left: 0.5em;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    animation: muevemueve 0.8s ease infinite;
    display: block;
    float: right;
}

.modal-cambiar-vista__item.seleccionada .modal-cambiar-vista__btn::before {
    content: "\f0a4";
    padding-right: 0.5em;
    float: left;
    animation: muevemueveleft 0.8s ease infinite;
}

.modal-cambiar-vista__btn {
    border: none;
    padding: 5px;;
    background: none;
    display: block;
    color: var(--color-principal)
}


.modal-cambiar-vista__item:hover  .modal-cambiar-vista__btn {
    font-weight: bold;
}

@keyframes muevemueve {
    0%, 100% {
        transform: translate(0, 0);
    }

    50% {
        transform: translate(6px, 0);
    }
}

@keyframes muevemueveleft {
    0%, 100% {
        transform: translate(0, 0);
    }

    50% {
        transform: translate(-6px, 0);
    }
}
