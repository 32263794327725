.footer {
  background-color: var(--color-secundario);
  color: var(--color_texto);
}

.footer a {
  color: var(--color_texto);
  transition: color 200ms linear;
}

.footer a:hover {
  text-decoration: none;
  color: var(--color_texto_hover);
  transition: color 200ms linear;
}

.footer__list {
  list-style: none;
  padding: 0;
}

.footer__list-item {
  font-size: .8rem;
}

.footer .fa-inverse {
  color: var(--color-secundario);
  transition: color 200ms linear;
}

.footer .fa-stack:hover .fa-stack-2x {
  color: var(--color_texto);
  transition: color 200ms linear;
}

.footer .fa-stack:hover .fa-facebook {
  color: #1877f2;
  transition: color 200ms linear;
}

.footer .fa-stack:hover .fa-twitter {
  color: #1da1f2;
  transition: color 200ms linear;
}

.footer .fa-stack:hover .fa-instagram {
  color: #db2977;
  transition: color 200ms linear;
}

.footer .fa-stack:hover .fa-youtube {
  color: #ff0000;
  transition: color 200ms linear;
}

.footer button {
  border: none;
  color: white;
  font-size: 0.8em;
  padding: 0;
}

.footer form {
  display: flex;
  flex-direction: column;
}

@media (max-width: 767px) {
  .footer {
    text-align: center;
  }
}
