
.modal-km__wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: grid;
    background: rgba(255,255,255, .6);
    z-index: 5000;
    font-size: 0.8em;
}

.modal-km__modal {
    background: var(--color-secundario);
    justify-self: center;
    align-self: center;
    border-radius: 6px;
    z-index: 5000;
    max-width: 80%;
    max-height: 90%;
}
