.modal-usuarios__lista > li {
  background-color: var(--color-secundario);
  color: #fff;
  border-bottom: 1px dashed #fff;
}

.modal-usuarios__avatar {
  width: 4rem;
  max-height: 4rem;
  background-color: #fff;
  object-fit: cover;
}

.modal-usuarios__icon .fa-inverse {
  color: var(--color-secundario);
}

.moda-usuarios__toggle {
  cursor: pointer;
}

.modal-usuarios__lista--elementos > li {
  border-bottom: none;
  cursor: pointer;
}
