.notificacion {
  background-color: var(--color-principal);
  color: var(--color-secundario);
  text-transform: initial;
}

.notificacion--leida {
  background-color: #FFFFFF;
}

.notificacion a {
  color: var(--color-secundario)
}
