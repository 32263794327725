.mapa-fullscreen > .leaflet-container {
  background: transparent;
  top: 10%;
  height: 90%;
}

.mapa-fullscreen {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url('../lib/images/fondo-mapa-captura.svg') repeat;
  background-size: cover;
}

.mapa-fullscreen .leaflet-control-container {
  display: none;
}
