.herramientas-busqueda,
.buscador {
  font-family: var(--font-family);
}

.herramientas-busqueda .css-dmx3ll-InputIcon-left {
  top: 7px;
}

.visor-publico--buscar {
  font-family: var(--font-family);
}

.visor-publico--buscar .css-iioxla-Button {
  background-color: var(--color-terciario);
  font-family: var(--font-family);
}

.visor-publico--buscar .css-iioxla-Button:hover{
  text-decoration: none;
}

@media (max-width: 575px) {
  .herramientas-busqueda {
    font-size: 3.6vw;
    margin-top: 1em;
    border-radius: 6px;
    padding: 5px;
  }

  .herramientas-busqueda .btn {
    border: none;
    padding: 0;
  }

  .herramientas-busqueda .form-group {
    padding: 5px;
    margin-bottom: 0;
  }

  .herramientas-busqueda #div_id_ordenar label {
    display: none;
  }
}


