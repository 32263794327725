.rec-list-canal__row {
  padding: 15px 0;
  color: var(--color_texto_secundario);
}

.rec-list-canal__row:hover {
  text-decoration: none;
  color: var(--color_texto_secundario_hover);
}

.rec-list-canal__row-impar {
  background-color: var(--color-terciario);
}

.rec-list-canal__icon {
  margin-right: 5px;
  display: block;
  width: 35px;
}

.rec-list-canal__captura {
  max-width: 120px;
}

.rec-list-canal__captura > img {
  max-width: 100%;
}

.rec-list-canal__cont-sectores {
  text-align: right;
}

.rec-list-canal__lista-sector {
  list-style: none;
  text-align: left;
  margin: 0;
  padding: 0;
}

.rec-list-canal__titulo-sector {
  padding: 5px 10px;
}

.rec-list-canal__dist-sector{
  float: right;
}

.recorrido-list__proyecto-nombre {
  background-color: rgba(255, 255 ,255 , 0.7);
  padding: 5px 15px 0 15px;
  font-size: .9em;
  line-height: 1em;
}

.recorrido-list__recorrido-nombre--canal{
  line-height: 1em;
  padding: 0 15px 5px 15px;
}
