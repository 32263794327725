.lista-subcabecera {
  width: 100%;
  height: auto;
  background-color: var(--color_fondo);
  z-index: 10;
  position: relative;
  padding: 20px 60px;
  display: inline-flex;
}

.lista-subcabecera__titulo {
  margin-left: 20px;
  display: inline-block;
  color: var(--color_texto);
  line-height: 1em;
}

.lista-subcabecera__titulo h2{
  margin-bottom: 0;
}

.lista-subcabecera__avatar > img {
  width: 7vw;
  height: 7vw;
  max-height: 140px;
  max-width: 140px;
  min-height: 75px;
  min-width: 75px;
  object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color_texto);
  border: 5px solid #fff;
}

.lista-subcabecera__link {
  color: var(--color_texto);
}

.lista-subcabecera__link:hover {
  text-decoration: none;
  color: var(--color_texto_hover);
}

.lista-contenido {
  width: 100%;
  max-width: 100%;
  position: relative;
  z-index: 1;
}

.lista__col-mapa {
  padding: 0;
}

.lista-contenido .container-map-portada {
  margin-top: 0;
  height: calc(100vh - 30vh)
}

.lista__col-listado{
  height: calc(100vh - 30vh);
  overflow: auto;
}

.lista-subcabecera .btn {
  color: var(--color-principal);
  position: absolute;
  right: 0.5em;
  top: 0.5em;
}

.lista-subcabecera .btn.perfil__btn-share{
  right: 2.5em;
}

.lista__col-mapa img.lista__logo {
  z-index: 400;
  width: 60px;
  position: relative;
  left: 10px;
  bottom: 60px;
  border-radius: 5px;
  padding: 3px 6px;
}

@media (max-width: 640px) {
  .lista-subcabecera {
    padding: 14px 14px;
  }

  .lista-subcabecera__titulo {
    margin-left: 0;
    display: block;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .lista-subcabecera__avatar {
    width: 75px;
    height: 75px;
    display: flex;
    position: absolute;
    bottom: -50px;
    right: 20px;
  }
}

@media (max-width: 768px) {
  .lista-subcabecera__titulo {
    font-size: 2.5vh;
  }
  .lista-subcabecera__titulo h2 {
    font-size: 4vh;
  }

  .lista-contenido .container-map-portada {
    height: 30vh;
  }
  .lista__col-mapa {
    height: 30vh;
    overflow: hidden;
  }
  .lista__col-listado{
    overflow: auto;
    height: 55vh;
  }
  .lista-subcabecera {
    height: 15vh;
  }
}
