.info-pdi__container {
  width: 30vw;
  height: 40px;
  background-color: var(--color_btn_shadow_secundario);
  border-radius: .25rem;
  transition: background-color 500ms ease-out, color 500ms ease-out;
  color: var(--color-secundario);
}

.info-pdi__container--cambio {
  background-color: var(--color-secundario);
  color: var(--color-principal);
  transition: background-color 100ms ease-out, color 100ms ease-out;
}

.info-pdi__nombre {
  font-size: 1.3em;
}

.info-pdis__servicio {
  width: 25px;
  height: 25px;
}

.info-pdis__servicio > i {
  font-size: 1rem;
}

@media (max-width: 768px) {
  .info-pdi__container {
    width: 90vw;
  }
}
