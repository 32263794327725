.perfiles-lista.mapa-modal{
    max-width: unset;
    border-radius: 0;
}

.perfiles-lista.mapa-modal .mapa-submodal {
    max-width: 800px;
    margin: 0 auto;
}

.table-perfil-resumen {
    width: 100%;
    max-width: 100%;
    margin-top: 2em;
    border-collapse: collapse;
}

.table-perfil-resumen td {
    border-bottom: 2px dashed var(--color-terciario);
    padding: .5em .2em;
    white-space: nowrap;
    text-align: right;
}
.table-perfil-resumen td a {
    color: white;
}

.table-perfil-resumen tr > td.perfil-link {
    cursor: pointer;
}

@media (max-width: 440px) {
    .table-perfil-resumen {
        font-size: 2.9vw;
    }
}

.control-expanded .mapa-modal__wrapper.perfil__wrapper .mapa-submodal__body > div {
    margin-top: 10px;
    border-radius: 6px;
}

.mapa-submodal__body {
    max-width: 92vw;
    margin-top: 0.5em;
}

.mapa-submodal__body .chartjs-size-monitor {
    margin-top: 0.5em;
}


@media only screen and (max-device-height: 540px) and (orientation : landscape) {
    .control-expanded .mapa-modal__wrapper.perfil__wrapper {
        min-height: 90vh;
        position: fixed;
        top: 0px;
        width: 100%;
        padding: 0;
        overflow-y: auto;
        z-index: 10;
        height: 100%;
    }

    .control-expanded .mapa-modal__wrapper.perfil__wrapper .mapa-submodal__body > div {
        margin-top: 5px;
    }
}
