body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


:root {
    --color-principal: #FFCC00;
    --color-secundario: #1A1A1A;
    --color-terciario: #FFFFFF;
    --color-gris: #A9A9A9;
    --color-gris-oscuro: #5e5e5e;
    --color-principal-opuesto: --color-secundario;
    --color-secundario-opuesto: #FFFFFF;
    --color-rojo-destacado: #ff4444;

    /*--color-gris: #A3A3A3;*/

    --top-bar-height: 6px;
    --footer-height: 100px;
}

@media (min-width:1900px) {
    :root {
        --footer-height: 120px;
    }
}

@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@300;600;700&display=swap');

:root{
    --color_fondo: #1a1a1a;
    --color_fondo_hover: #000;
    --color_fondo_mas_claro: #333;
    --color_fondo_secundario: #ffcc00;
    --color_fondo_secundario_hover: #f3c000;
    --color_fondo_seleccionado: #626262;
    --color_texto: #f3f3f3;
    --color_texto_hover: #fff;
    --color_texto_sobre_fondo: #ffcc00;
    --color_texto_sobre_fondo_hover: #f3c000;
    --color_texto_secundario: #444;
    --color_texto_secundario_hover: #000000;
    --color_fondo_modal: rgba(243, 243, 243, 1);
    --color_fondo_modal_secundario: #a9a9a9;
    --color_fondo_modal_secundario_hover: #919191;
    --color_btn_shadow: rgba(46, 46, 46, .5);
    --color_btn_shadow_secundario: rgba(243, 204, 0, .5);
    --font-family: 'Barlow Semi Condensed', Roboto, Arial, 'sans-serif';
}

body {
    font-family: var(--font-family);
    text-transform: uppercase;
}

button {
    text-transform: uppercase;
}

.btn-black {
  background: var(--color-secundario);
  color: var(--color-principal);
}
.btn-black:hover,.btn-black:active, .btn-black:focus {
  color: var(--color-principal);

}

p {
    text-transform: none;
}

a {
    color: var(--color-secundario);
    transition: color 200ms linear;
}

a:hover {
  color: #6C6C6C;
  text-decoration: none;
}

.modal-content {
    border-radius: 10px;
}

.fondo-carga{
    background-color: var(--color_fondo_secundario);
}


img.logo {
    width: 270px;
    max-width: 65vw;
}

.scroll-vertical {
    overflow-y: scroll;
    scrollbar-color: var(--color_fondo_secundario) var(--color-gris);
}
.scroll-vertical::-webkit-scrollbar {
    width: 12px;
}

.scroll-vertical::-webkit-scrollbar-track {
}

.scroll-vertical::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: var(--color_fondo_secundario)
}
.scroll-vertical::-webkit-scrollbar-track-piece  {
    background: var(--color-gris);
}

.scroll-vertical--oscuro {
    overflow-y: scroll;
    scrollbar-color: var(--color_fondo) var(--color-gris);
}
.scroll-vertical--oscuro::-webkit-scrollbar {
    width: 12px;
}

.scroll-vertical--oscuro::-webkit-scrollbar-track {
}

.scroll-vertical--oscuro::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: var(--color_fondo)
}
.scroll-vertical--oscuro::-webkit-scrollbar-track-piece  {
    background: var(--color-gris);
}



.main-grid--proyecto, .main-grid--proyecto-3, .main-grid--proyecto-4 {
    /*height: 100vh; */
    width: 100vw;

    /* https://developers.google.com/web/updates/2016/12/url-bar-resizing */
    position: fixed;
    height: 100%;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: var(--top-bar-height) var(--top-bar-height) auto var(--footer-height);
    /*overflow-y: auto*/
}

.main-grid--proyecto-3 {
    grid-template-rows: var(--top-bar-height) auto var(--footer-height);
}

.main-grid--recorrido {
    /*height: 100vh;*/
    width: 100vw;

    /* https://developers.google.com/web/updates/2016/12/url-bar-resizing */
    position: fixed;
    height: 100%;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: var(--top-bar-height) 1fr;
    /*overflow-y: auto*/
}

.main-grid--recorrido--privado {
  height: calc(100% - 50px);
}

.visor-publico {
    height: 100vh;
    width: 100vw;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: var(--top-bar-height) 1fr;
    font-family: var(--font-family);
    /*overflow-y: auto*/
}

.visor-publico .css-15g0dol-Base{
    font-family: var(--font-family);
}


.boton-visor {
    font-family: 'Barlow Semi Condensed', Roboto, Arial, 'sans-serif';
    width: 57px;
    height: 57px;
    display: inline-block;
    background-color: var(--color-principal);
    border-radius: 5px !important;
    text-align: center;
    margin: 15px;
    overflow: hidden;
    padding: 0;
    transition: background-color 0.3s ease;
}

.boton-visor:hover,
.boton-visor:active,
.boton-visor:focus{
    background-color: var(--color-terciario);
    border: 1px solid var(--color-secundario);
}

.boton-visor a {
    color: var(--color_fondo)
}
.boton-visor button,
.boton-visor > a {
    color: var(--color_fondo);
    background-color: transparent;
    border: none;
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.boton-visor .fas {
    margin-bottom: 5px;
    display: block;
}

.boton-visor > a:hover,
.boton-visor > a:active,
.boton-visor > a:focus{
    text-decoration: none;
}

.boton-visor.boton-destacado{
    background-color: var(--color-secundario);
    color: var(--color-principal);
    border: 1px solid var(--color-principal);
    transition: all 0.3s ease;
}

.boton-visor.boton-destacado:hover,
.boton-visor.boton-destacado:active,
.boton-visor.boton-destacado:focus{
    background-color: var(--color-terciario);
    color: var(--color-secundario);
    border: 1px solid var(--color-principal);
}

.boton-visor.boton-destacado:hover a,
.boton-visor.boton-destacado:active a,
.boton-visor.boton-destacado:focus a{
    color: var(--color-secundario);
}

.boton-visor.boton-destacado a{
    color: var(--color-principal);
}

.boton-visor .btn__texto {
    text-transform: uppercase;
    font-size: 10px;
    line-height: 1;
    display: block;
}

.boton-visor--small {
    width: 54px;
    height: 54px;
    margin: 8px 8px;
    display: table-cell;
    vertical-align: middle;
    font-size: 1.2rem;
    padding: 2px;
}

.boton-visor--small .btn__texto {
    text-transform: uppercase;
    font-size:  0.7em;
    line-height: 1;
}

@media (min-width: 1024px) {
    .boton-visor--small {
        width: 80px;
        height: 80px;
        font-size: 1.7rem;
    }
}

@media (min-width: 1900px) {
    .boton-visor--small {
        width: 95px;
        height: 95px;
        font-size: 2rem;
        padding: 5px;
    }
}

.boton-visor__group {
    padding: 0;
    position: center;
}

ul.boton-visor__group {
    margin-bottom: 0.5rem;
}

.sub-heramientas ul.boton-visor__group {
    margin-bottom: 0;
}

.sub-heramientas .boton-visor--small {
    margin-bottom: 0;
}

.sub-heramientas .boton-visor{
    background-color: var(--color-secundario);
    color: var(--color-principal);
}

.sub-heramientas .boton-visor:hover{
    background-color: var(--color-terciario);
}

.sub-heramientas .boton-visor button,
.sub-heramientas .boton-visor a{
    color: var(--color-principal);
}

.sub-heramientas .boton-visor:hover button,
.sub-heramientas .boton-visor:hover a{
    color: var(--color-secundario);
}

.boton-visor .fa-stack {
    font-size: 0.6em;
    margin-top: -0.5em;
}

.boton-visor .fa-stack-2x {
    opacity: 0.7;
}


/* Nova estrutura para evitar problemas coa barra de direccións en android */

.visor__layout {

}
.visor__layout .top-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99999;
}
.visor__layout .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

}
.visor__main {
    padding-top: var(--top-bar-height);
    padding-bottom: var(--footer-height);
}
.visor__main--no-footer {
    padding-bottom: 0;
}

.visor__main--perfil {
    padding-top: 0;
    padding-bottom: 0;
}

.visor__main--perfil .perfil__grafico-sizer {
    /* En este caso especial o gráfico debe ocupar o 100% da pantalla */
    height: 100vw;
    padding-top: 0;
}

@media only screen and (max-width: 700px) and (orientation:portrait) {
    .visor__layout--force-landscape {

        height: 100vw;
        width: 100vh;
        overflow: hidden;
        transform-origin: top left;
        /* Orden de aplicar transformacións: de dereita a esquerda*/
        transform: translateX(100vw) rotate(90deg) ;
    }

}


@media only screen and (orientation:landscape) {
    .visor__layout--force-landscape {
    }
    .visor__main--perfil .perfil__grafico-sizer {
        height: 100vh;
    }
}



/**
* Copiado das cousas do gráfico que tiña para a portada...
* O problema e que como non usou BEM e puxo os nombres das clases ó chou
* agora peganse uns estilos cos outros...
*/
.background-dark {
    background-color: #1a1a1a;
    color: #fff;
}
.background-light {
    background-color: #fff;
    color: #1a1a1a;
}
.background-brandcolor {
  background-color: var(--color-principal);
  color: #1a1a1a;
}

/* Portada */

img.logo-portada {
    max-width: 95vw;
    width: 640px;
}

.portada-descripcion,
.portada-info {
    text-transform: none;
    font-size: 1.4em;
    line-height: 1.3em;
}

.portada-info{
    padding: 6em 0;
    flex-direction: column;
}

.portada-info .info-item{
    max-width: 800px;
    display: flex;
    justify-content: space-between;
    padding: 1.3em 0;
    flex-direction: row;
    margin: 0 auto;
}

.portada-info .info-item.info-item-top{
    flex-direction: column;
    padding: 3em 0;
}

.portada-info .info-item .info-contenedor-imagen{
    width: 35%;
}

.portada-info .info-item .info-contenedor-imagen.info-contenedor-imagen-top{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 1.6em;
}

.portada-info .info-item .info-contenedor-imagen .info-imagen {
    width: 100%;
}

.portada-info .info-item .info-contenedor-imagen.info-contenedor-imagen-top .info-imagen{
    width: 70%;
}

.portada-info .info-item .info-texto{
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1.3em;
}

.portada-info .info-item .info-texto.info-texto-top {
    width: 100%;
    text-align: center;
}

.portada-info .info-item .info-texto .info-titulo{
    font-size: 1.4em;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1.1em;
    margin-bottom: 0.2em;
}

@media (max-width: 991px) {
    .portada-info .info-item  {
        max-width: 82vw;
    }
}

@media (max-width: 767px) {
    .portada-info .info-item  {
        max-width: 82vw;
        font-size: 2.8vw;
    }
    .portada-info .info-item .info-contenedor-imagen.info-contenedor-imagen-top .info-imagen{
        width: 95%;
        padding: 1em 0;
    }
}

@media (max-width: 575px) {
    .portada-info .info-item .info-texto,
    .portada-info .info-item .info-contenedor-imagen {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .portada-info .info-item,
    .portada-descripcion {
        justify-content: center;
        flex-direction: column;
        text-align: center;
        font-size: 4vw;
    }
    .portada-info .info-item .info-contenedor-imagen .info-imagen {
        width: 80vw;
        padding: 2em;
    }
    .portada-info .info-item.reverse{
        flex-flow: column-reverse;
    }
}

h2.portada-titular { text-transform: uppercase; font-size: 1.4em; border-bottom: 1px dashed #FFCC00; color: #FFCC00; padding-bottom: 5px; margin: 1.3em 0; }

h2.portada-titular .fas { padding-right: 0.3em; }

.map-card { color: #1A1A1A; background-color: #FFCC00; padding: 3px 9px 9px 9px; border-radius: 6px; text-transform: uppercase; margin-bottom: 30px; }

.map-card-title { max-height: 1.6em; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; font-weight: bold; font-size: 1em; }

.map-card-date { font-size: 0.7em; line-height: 1em; margin-bottom: 5px; }

.btn-amarillo {
    color: #212529;
    background-color: #fc0;
    border-color: #1a1a1a;
}

ul#idiomas { list-style: none; padding-left: 0; }

#idiomas button { background-color: transparent; color: #fff; border: none; text-transform: uppercase; }

#idiomas .bandera { width: 25px; margin-right: 0.3em; }

@media (max-width: 767px) { #idiomas .idioma .texto { display: none; } }


/* Cabecera */

.cabecera {
    background-color: var(--color_fondo);
}

.cabecera .titulo {
    color: var(--color_texto);
}

.cabecera .titulo:hover {
    color: var(--color_texto_hover);
}

.cabecera .navbar-brand {
    padding-bottom: 1px;
}

.cabecera .navbar-collapse {
    background-color: var(--color-secundario);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.cabecera .navbar-nav .btn-cabecera-editor{
    color: var(--color_texto);
}

.cabecera .navbar-nav .btn-cabecera-editor:hover{
    color: var(--color_texto_hover);
}

.cabecera .navbar-nav .btn-cabecera-editor:focus{
    color: var(--color_texto_hover);
    outline: none;
}

.cabecera .navbar-nav .btn-cabecera-editor-usuarios{
    color: var(--color_texto_sobre_fondo);
}

.cabecera .navbar-nav .btn-cabecera-editor-usuarios:hover{
    color: var(--color_texto_sobre_fondo_hover);
}

.cabecera .navbar-nav .btn-cabecera-editor-usuarios:focus{
    color: var(--color_texto_sobre_fondo_hover) !important;
    outline: none;
}

.cont-btn-cabecera-edit.show > .nav-link{
    background-color: var(--color_fondo_mas_claro);
}

.cont-btn-cabecera-edit-users{
    border-color: var(--color_texto);
}

.editor-barra-izquierda {
    background-color: var(--color_fondo_mas_claro);
}

.editor-acordeon-header {
    background-color: var(--color_fondo);
    color: var(--color_texto_sobre_fondo);
}

.editor-acordeon-body{
    color: var(--color_texto)
}

.editor-acordeon-body ul > .seleccionado{
    background-color: var(--color_fondo_seleccionado);
}

.editor-acordeon-body .editor-acordeon-marca-color {
    border: 1px solid #FFFFFF;
}

.subcabecera-editor {
    background-color: var(--color_fondo_secundario);
}

.btn-toggle-esquema-edit{
    border-right: 1px solid var(--color_texto_secundario) !important;
}

.btn-toggle-esquema-edit:hover,
.btn-toggle-esquema-edit:active,
.btn-toggle-esquema-edit:focus{
    border-right: 1px solid var(--color_texto_secundario_hover) !important;
}

.subcabecera-editor-barra-izquierda{
    border-right: 1px solid var(--color_texto_secundario) !important;
}

.btn-add {
    background-color: var(--color_fondo);
    color: var(--color_texto_sobre_fondo);
}

.btn-add:hover,
.btn-add:active,
.btn-add:focus{
    background-color: var(--color_fondo_hover);
    color: var(--color_texto_sobre_fondo_hover);
}

.ico-nada-anadido{
    color: var(--color_texto_sobre_fondo);
}

.txt-nada-anadido{
    color: var(--color_texto_sobre_fondo);
}

.diag-editor .modal-content{
    background-color: transparent;
}

.diag-editor .modal-header{
    background-color: var(--color_fondo);
    color: var(--color_texto_sobre_fondo);
}

.diag-editor .modal-header .close {
    background-color: var(--color_texto);
    color: var(--color_fondo);
    opacity: 1;
    border-radius: 6px;
}

.diag-editor .modal-header .close:hover,
.diag-editor .modal-header .close:active,
.diag-editor .modal-header .close:focus {
    background-color: var(--color_texto_hover);
    color: var(--color_fondo_hover);
    opacity: 1;
}

.diag-editor .modal-body{
    background-color: var(--color_fondo_modal);
}

.diag-editor .modal-footer{
    background-color: var(--color_fondo_modal);
}

.btn-biblio > button{
    background-color: var(--color_fondo);
    color: var(--color_texto);
}

.btn-biblio > button:hover,
.btn-biblio > button:active,
.btn-biblio > button:focus {
    background-color: var(--color_fondo_hover);
    color: var(--color_texto_hover);
}

.btn-biblio-servicios > button:hover {
    color: var(--color_fondo_secundario);
}

.btn-biblio-servicio-seleccionado > button {
    background-color: var(--color_fondo_secundario);
    color: var(--color_fondo);
}

.btn-biblio-servicio-seleccionado > button:hover,
.btn-biblio-servicio-seleccionado > button:active,
.btn-biblio-servicio-seleccionado > button:focus {
    background-color: var(--color_fondo_secundario_hover);
    color: var(--color_fondo_hover);
}


/* Nota do gráfico: Esto está cheo de importants! que non é recomendable usalos,
 como non sei moi ben porque se puxeron nin quen os puxo vou deixalos, porqué é
 un rollo saber para que serven e se se poden borrar, pero organizando ben o css
 non deberían facer falta.*/

.btn-biblio:focus {
    -webkit-box-shadow: 0 0 0 0.2rem var(--color_btn_shadow) !important;
    -moz-box-shadow: 0 0 0 0.2rem var(--color_btn_shadow) !important;
    box-shadow: 0 0 0 0.2rem var(--color_btn_shadow) !important;
}

.btn-barra-herramientas-editor {
    background-color: var(--color_fondo_secundario);
    color: var(--color_texto_secundario);

}

.btn-barra-herramientas-editor:hover,
.btn-barra-herramientas-editor:active,
.btn-barra-herramientas-editor:focus {
    background-color: var(--color-secundario) !important;
    color: var(--color-principal) !important;
}

.btn-barra-herramientas-editor:focus{
    -webkit-box-shadow: 0 0 0 0.2rem var(--color_btn_shadow_secundario) !important;
    -moz-box-shadow: 0 0 0 0.2rem var(--color_btn_shadow_secundario) !important;
    box-shadow: 0 0 0 0.2rem var(--color_btn_shadow_secundario) !important;
}

.btn-barra-herramientas-eliminar {
    background-color: var(--color-secundario) !important;
    color: var(--color-principal) !important;
}

.btn-barra-herramientas-eliminar:hover,
.btn-barra-herramientas-eliminar:active,
.btn-barra-herramientas-eliminar:focus {
    background-color: var(--color-principal) !important;
    color: var(--color-secundario) !important;
}

.btn-barra-herramientas-eliminar:focus{
    -webkit-box-shadow: 0 0 0 0.2rem var(--color_btn_shadow) !important;
    -moz-box-shadow: 0 0 0 0.2rem var(--color_btn_shadow) !important;
    box-shadow: 0 0 0 0.2rem var(--color_btn_shadow) !important;
}

.display-distancias{
    background-color: var(--color_fondo_modal);
    color: var(--color_texto_secundario)
}

.display-trayecto-crear-recorrido{
    background-color: var(--color_fondo);
}

.display-trayecto-crear-recorrido:hover {
    background-color: var(--color_fondo_hover);
}

.display-trayecto-crear-recorrido > .nombre-trayecto {
    color: var(--color_texto);
}

.display-trayecto-crear-recorrido:hover > .nombre-trayecto {
    color: var(--color_texto_hover);
}

.display-trayecto-crear-recorrido > .distancia-trayecto {
    color: var(--color_texto_secundario);
}

.display-trayecto-crear-recorrido:hover > .distancia-trayecto {
    color: var(--color_texto_secundario_hover);
}

.contenedor-lista-sectores{
    background-color: var(--color_fondo_modal_secundario);
}

.contenedor-lista-sectores.vacia > .msg {
    color: var(--color_texto_secundario)
}

.contenedor-lista-sectores.is-drag-over{
    background-color: var(--color_fondo_modal_secundario_hover);
}

.tabla-trayectos-sector > tbody > tr {
    background-color: var(--color_fondo);
    color: var(--color_texto);
}

.contenedor-lista-sectores.is-drag-over .tabla-trayectos-sector > tbody > tr {
    border-bottom: 3px solid var(--color_fondo_modal_secundario_hover);
}

.tabla-trayectos-sector .titulo-trayecto{
    color: var(--color_texto_secundario)
}

.tabla-trayectos-sector .basura-trayecto{
    color: var(--color_texto_sobre_fondo);
}

.tabla-trayectos-sector .basura-trayecto:hover {
    color: var(--color_texto_sobre_fondo_hover);
}

.encabezado-sector .titulo {
    color: var(--color_texto_secundario);
}

.encabezado-sector .titulo.anadir:hover {
    color: var(--color_texto_secundario_hover);
}

.display-trayecto-cabecera {
    background-color: var(--color_fondo);
}

.display-trayecto-cabecera .nombre-trayecto {
    color: var(--color_texto);
}

.display-trayecto-cabecera .repeticiones-trayecto{
    background-color: var(--color_fondo_seleccionado);
}

.cabecera .nav-pills .nav-link {
    border-radius: 0;
}

.cabecera .nav-link {
    padding: 0.4rem 1rem;
}

.tabla-organizar-recorridos > tbody > tr {
    background-color: var(--color_fondo);
    color: var(--color_texto);
}

.tabla-organizar-recorridos > tbody > tr {
    border-bottom: 3px solid var(--color_fondo_modal);
}

.tabla-organizar-recorridos > tbody > tr.fila-eliminado {
    background-color: var(--color-rojo-destacado);
}



/* Dropdown */

.dropdown-menu {
  /*
  background-color: var(--color_fondo);
    color: var(--color_texto_sobre_fondo);
   */
    padding: 0;
    border-radius: 6px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    overflow: hidden;
}

.dropdown-menu > a{
  border-top: 1px dotted var(--color-secundario) !important;
  padding-right: 2.4em;
}

.dropdown-menu > a:first-child{
    border-top: none;
}

.dropdown .dropdown-divider {
    margin: 0;
    border-top: 1px solid var(--color-secundario);
}

.dropdown-item-recorridos svg {
    margin-right: 5px;
    display: inline-block;
}

.dropdown-menu svg.eliminar-recorrido{
    right: 0;
    position: absolute;
    margin-right: 1em;
}

.dropdown-item-recorridos > .eliminar-recorrido {
    float: right;
}

.cabecera-navitem-nombre {
    max-width: 19em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}


.navbar-nav .nav-link {
  text-align: left;
}
.navbar-nav .nav-link .dropdown-menu {
  background-color: transparent;
}
.navbar-nav .nav-link .dropdown-menu .dropdown-item {
  color: var(--color-principal);
  text-align: left;
  padding-left: 20px;
  border-top: 1px dotted var(--color-principal) !important;
}
.navbar-nav .nav-link .dropdown-toggle::after {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 20px;
  transform: rotate(-90deg);
  /* transition: all 300s ease-in-out;*/
}
.navbar-nav .nav-link.show .dropdown-menu {
  padding-bottom: 16px;
}
.navbar-nav .nav-link.show .dropdown-toggle .texto {
  color: var(--color-principal)
}
.navbar-nav .nav-link.show .dropdown-toggle::after {
  transform: rotate(0deg);
  color: var(--color-principal)
}

@media (min-width: 768px) {
  .navbar .nav-item {
    transition: background-color 300ms ease-in-out;
  }

  .navbar .nav-item:hover,
  .navbar .nav-item.show {
    background: var(--color-principal);
    /*color: white;*/
    color: var(--color-secundario);
    z-index: 1001;
  }

  .navbar .nav-item .texto,
  .navbar .nav-item .dropdown-toggle:after,
  .navbar .nav-item i.fas,
  .navbar .nav-item svg {
    transition: color 300ms ease-in-out;
  }

  .navbar .nav-item:hover .texto,
  .navbar .nav-item.show .texto,
  .navbar .nav-item:hover .dropdown-toggle:after,
  .navbar .nav-item.show .dropdown-toggle:after,
  .navbar .nav-item:hover i.fas,
  .navbar .nav-item:hover svg,
  .navbar .nav-item.show i.fas,
  .navbar .nav-item.show svg {
    color: var(--color-secundario);
  }

  .navbar .nav-item.show .dropdown-menu.show {
    background-color: var(--color-principal);
    color: var(--color-secundario);
    margin: -1px !important;
    border-top: none;
  }

  .navbar-nav .nav-link {
    text-align: center;
  }
  .navbar-nav .nav-link .dropdown-toggle::after {
    position: relative;
    right: auto;
    top: auto;
    transform: none;
  }
  .navbar-nav .nav-link.show .dropdown-toggle .texto {
    color: var(--color-secundario)
  }
  .navbar-nav .nav-link .dropdown-menu .dropdown-item {
    color: var(--color-secundario);
    border-color: var(--color-secundario) !important;
  }
}


/* Formularios */

.form-control {
    border-radius: 6px;
    border: 1px solid var(--color_fondo);
}

label.form-label {
    font-size: 1.1em;
    font-weight: bold;
    margin-bottom: 0.1rem;
}

/* Leaflet */

.leaflet-container {
    font-family: 'Barlow Semi Condensed', Roboto, Arial, 'sans-serif';
}

.leaflet-touch .leaflet-control-attribution {
    text-transform: none;
}

/* Botones */

.btn-primary {
    background-color: var(--color-principal);
    color: var(--color-secundario);
    border: none;
}

.btn-primary:hover{
    background-color: var(--color-terciario);
    color: var(--color-secundario);
}

.btn-secondary {
    background-color: var(--color-secundario);
    color: var(--color-principal);
    border: none;
}

.btn-secondary:hover{
    background-color: var(--color-terciario);
    color: var(--color-secundario);
}

/* Modales */

.modal-title {
    font-weight: bold;
}

.modal-content .rw-multiselect-tag {
    margin: 0 0 6px 6px;
}

/* Dificultad */

.ais-Hits .fa-circle {
    font-size: 0.8em;
}

.facil {
    color: #49b738 !important;
}

.media {
    color: #d1b110 !important;
}

.moderada {
    color: #b77302 !important;
}

.dificil {
    color: #b70000 !important;
}

.muydificil {
    color: #7103b7 !important;
}

.aparece-abajo {
    animation-name: aparece-abajo;
    animation-duration: 0.5s;
    animation-timing-function: ease;
}

.aparece-arriba {
    animation-name: aparece-arriba;
    animation-duration: 0.5s;
    animation-timing-function: ease;
}

@keyframes aparece-abajo {

    from {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
        visibility: visible;
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }

}

@keyframes aparece-arriba {

    from {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
        visibility: visible;
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }

}


@media (max-width: 767px) {
    .visor-publico--buscar .container {
        max-width: 88vw;
    }
}

/* Flechas del perfil */

.modal-editor-perfil .flechas {
    position: absolute;
    z-index: 10;
    margin-top: 0.6em;
}


.modal-editor-perfil .flecha-dch {
    right: 0px;
}

.modal-editor-perfil .flecha-izq {
    left: 0px;
}

.fa-rotate-45 {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.fas.fa-pull-bottom {
    margin-bottom: -0.5em;
    bottom: 0px;
}

.table-perfil-resumen .fa-stack {
    width: 1em;
}

.spinner-respuesta-servidor{
    border-color: var(--color_texto_sobre_fondo);
    border-right-color: transparent;
}


/* Perfiles visor: Datos de la leyenda del perfil */


ul.perfil-data {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

ul.perfil-data li{
    height: 1.8em;
}

ul.perfil-data .icon{
    font-size: 0.8em;
    width: 1.5em;
    display: flex;
    float: left;
    justify-content: center;
    align-items: center;
    padding: 0.6em 1.6em;
}

.fondo-carga {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  align-content: center;
  overflow: hidden;
}

.spinner-carga {
  width: 6rem;
  height: 6rem;
  display: block;
  margin: auto;
}

.spinner-carga-sm {
  width: 2rem;
  height: 2rem;
}

.portada-texto-encargo {
  color: var(--color-principal);
  font-size: 1rem;
}

