.home__recorrido {
  background-color: var(--color-principal);
  color: var(--color-secundario);
  transition: background-color 400ms ease;
}

.home__recorrido:hover {
  background-color: var(--color-terciario);
}

.home__recorrido--cargando {
  height: 300px;
}


.home__list-language {
  background-color: transparent;
  border-radius: 0;
  color: var(--color-secundario);
}
.home__img-lang {
  border: 1px solid var(--color-secundario);
  height: 20px;
}
.home__icono-idiomas {
  /*color: var(--color-principal);*/
}
