.proyecto__avatar {
  width: 130px;
  height: 130px;
  background-color: #fff;
  border-radius: 50%;
  border: 10px solid #fff;
}

.proyecto__avatar > img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  object-fit: cover;
}

.proyecto__link-canal {
  font-size: 1.1rem;
}

.proyecto__btn-compartir {
  bottom: 1rem;
  right: 1rem;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: var(--color-secundario);
  color: var(--color-principal);
  border: 3px solid var(--color-principal);
  outline: none;
}

.proyecto__btn-compartir:focus {
  outline: none;
}

.proyecto__lista {
  padding-bottom: 5rem;
}

.proyecto__contenido {
  position: absolute;
  height: calc(100% - 60px);
  margin-top: 60px;
  width: 100%;
}

@media (max-width: 768px) {
  .proyecto__avatar {
    width: 80px;
    height: 80px;
  }
}
