.ficha-contacto {
  max-width: 500px;
  line-height: 1.2em;
  border-bottom: 1px dashed var(--color-gris);
}

.contacto-add .row:last-child .ficha-contacto{
  border-bottom: none;
}

.ficha-contacto__avatar {
  width: 4em;
  max-height: 4em;
  object-fit: cover;
  border: 3px solid var(--color-secundario);
}

@media (max-width: 500px) {
  .ficha-contacto {
    max-width: 100%;
  }
}
