.mapa-portada__wrapper {
  width: 100%;
  height: 500px;
  overflow: hidden;
  display: flex;
}

.mapa-portada__container{
  width: 100%;
  height: 100%;
  transition: width 200ms linear;
}

.mapa-portada__container--collapsed {
  width: calc(100% - 300px);
  transition: width 200ms linear;
}

.mapa-portada__recorrido-detalle {
  position: relative;
  top: 0;
  height: 100%;
  width: 0px;
  right: -200px;
  background-color: var(--color-principal);
  color: var(--color-secundario);
  transition: right 200ms linear, width 200ms linear;
}

.mapa-portada__recorrido-detalle--abierto {
  width: 300px;
  right: 0;
  padding: 1rem;
  transition: right 200ms linear, width 200ms linear;
}

.titulo-proyecto {
  text-align: center;
}

.icono-mapa-deporte{
  width: 30px !important;
  height: 30px !important;
  margin-left: -15px !important;
  margin-top: -15px !important;
  background-color: var(--color_fondo_secundario);
  border-radius: 50%;
  padding: 5px;
}

.icono-mapa-deporte.highlated{
  width: 50px !important;
  height: 50px !important;
  margin-left: -20px !important;
  margin-top: -20px !important;
  background-color: var(--color-secundario);
  border-radius: 50%;
  padding: 5px;
}

.icono-mapa-deporte > svg {
  width: 100% !important;
  height: 100%;
  stroke-width: 0;
}

.icono-mapa-deporte > svg path {
  fill: var(--color_fondo);
  stroke: var(--color_fondo);
  stroke-width: 0;
}

.icono-mapa-deporte.highlated > svg path {
  fill: var(--color-principal);
  stroke: var(--color-principal);
}

.leaflet-container a.leaflet-control-zoom-in {
  color: var(--color_fondo);
}

.leaflet-container a.leaflet-control-zoom-out {
  color: var(--color_fondo);
}

.mapa-portada__recorrido-cabecera {
  background-color: rgba(255, 255 ,255 , 0.7);
  padding: .5rem 1rem;
}

.mapa_portada__cerrar-detalle {
  background-color: transparent;
  border: none;
  position: absolute;
  right: 0;
  top: 0;
  margin: 1rem;
}

.mapa_portada__cerrar-detalle:focus,
.mapa_portada__cerrar-detalle:active {
  outline: 0;
}

.mapa-portada__recorrido-deporte {
  width: 39px;
  max-height: 39px;
  background-color: var(--color-secundario);
  color: var(--color-principal);
  padding: 3px;
}

.mapa-portada__recorrido-datos {
  text-transform: initial;
}

.mapa-portada__recorrido-datos-arriba {
  font-size: 0.8em;
}

.mapa-portada__recorrido-datos-abajo {
  font-size: 0.8em;
  background-color: var(--color-secundario);
  color: var(--color-principal);
}

.mapa-portada__logo-proyecto {
  width: 80px;
  height: 80px;
  margin-bottom: 1rem !important;
  background-color: #fff;
  border: 5px solid #fff;
}

.mapa-portada__logo-proyecto > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.mapa-portada__recorrido-descripcion {
  font-size: 0.8em;
}
