
.mapa-submodal__lista--pdis button {
    background: transparent;
    border: none;
    color: var(--color-principal);
    display: block;
}

.mapa-submodal__lista--pdis .card {
  padding: 0;
  border: none;
}

.mapa-submodal__lista--pdis .card-header {
  background-color: var(--color-principal);
  color: var(--color-secundario);
  border: none;
  padding: .5rem 1.25rem;
}

.mapa-submodal__lista--pdis .card-header h6 {
  font-weight: 700;
}

.mapa-submodal__lista--pdis .list-group {
  background-color: var(--color-secundario);
}

.mapa-submodal__lista--pdis .list-group-item {
  background-color: var(--color-secundario);
  color: var(--color-terciario);
  border: none;
  border-bottom: 1px dashed var(--color-terciario);
  margin-bottom: 0;
  cursor: pointer;
  font-weight: 600;
  display: flex;
  line-height: 30px;
}
