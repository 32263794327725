

.perfil__wrapper {
    background: var(--color-secundario);
    color: var(--color-terciario);
    width: 100%;
    min-width: 300px;
    height: 100vh;
    overflow: hidden auto;
    margin: 0 auto;
}

.perfil__grafico-sizer {
    background: var(--color-secundario);
    border-radius: 6px;
    padding-top: 86%; /* relación de aspecto */
    position: relative;
}
.perfil__grafico {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 0 10px;
}

.perfil__grafico .VictoryContainer {
    text-transform: none;
}

.perfil__grafico .btn-group {
  float: right;
}

.perfil__grafico .btn {
  color: var(--color-principal);
}

.perfil__grafico .btn.disabled {
  color: var(--color-gris-oscuro);
}

.perfil__grafico .btn.focus, .perfil__grafico .btn:focus {
  box-shadow: none;
}

.perfil__topbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.perfil__topbar h3{
  margin-bottom: 2px;
  margin-top: 6px;
  font-size: 0.9rem;
  text-align: left;
  color: var(--color-principal);
  max-width: calc(100% - 85px);
  min-width: 50%;
}

.perfil__topbar h3 span{
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.perfil__proyectonombre {
  max-width: 49%;
  text-align: right;
}

.perfil__recorridonombre {
  max-width: 49%;
  text-align: left;
}

.perfil__tabla {
    color: var(--color-principal);
    padding: 0.2em 4px;
    text-align: center;
    font-size: .8em;
}

.perfil__tabla h5{
  margin-bottom: 0;
}

.perfil__selectorsectores {
  background-color: var(--color-principal);
  color: var(--color-secundario);
  margin-bottom: 3px;
}

.perfil__selectorsectores .btn {
  color: var(--color-secundario);
  padding: 0;
}

.perfil__datos h6 {
  font-weight: 600;
}

.perfil__topbar .btn {
  font-size: 1.3em;
  padding-left: 20px;;
}

img.perfil_logo {
  max-height: 28px;
  display: block;
  float: left;
  margin-right: 10px;
}

.perfil_mosca {
  bottom: 41px;
  position: absolute;
  max-width: 18vw;
  right: 31px;
  opacity: 0.3;
  pointer-events: none;
}

@media (orientation: landscape) {

    .perfil__grafico-sizer {
        /* restamos un em padding... */
        height: calc(100vh - var(--top-bar-height) - var(--footer-height) - 1em);
        padding-top: 0;
    }
}


.victory__chart-title {
    font-weight: bold;
    font-family: var(--font-family);
}
.victory__chart-subtitle {
    text-transform: capitalize;
    font-family: var(--font-family);
}

.mdi,
.mdi-set {
    display: inline-block;
    font: normal normal normal 24px/1 "Material Design Icons";
    font-size: inherit;
    text-rendering: auto;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.visor__main--perfil-captura .perfil__wrapper {
    padding: 0;
}
.visor__main--perfil-captura .perfil__grafico-sizer {
    height: 100vh;
    border-radius: 0;
}
