
.compartir__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--color-secundario);
}

.compartir__ul {
    list-style: none;
    padding: 15px;
    text-align: center;
}

.compartir__item {
    display: inline-block;
    margin: 20px;
}

.compartir__close {
    border: none;
    border-radius: 6px;
    background: none;
    color: var(--color-principal);
    font-size: 50px;
    line-height: 30px;
    font-weight: bold;
    margin: 20px;
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 2em;
}

.compartir__title {
    color: var(--color-principal);
    font-size: 30px;
    margin: 20px;
    text-align: center;
}

.react-share__btn-label {
    font-size: .8rem;
    color: white;
    text-transform: uppercase;
    margin-top: .4rem
}


.compartir__link {
    width: 80%;
    max-width: 40rem;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    background: white;
    padding: .5rem 1rem;
}
.compartir__link-input {
    border: none;
    outline: none;
    flex-grow: 1;
    text-align: center;
}
.compartir__link-btn {
    background: none;
    border: none;
    font-weight: bold;
    background-color: var(--color-principal);
}

@keyframes compartirOpacityOnAndOff {
    0% {
        opacity: 0;
    }
    20%{
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.compartir__copiado {
    opacity: 0;
    text-align: center;
    color: white;
}

.compartir__copiado--blink {
    animation: compartirOpacityOnAndOff 4s normal forwards;
}


.compartir__iframe-wrapper {
    display: flex;
    flex-direction: row;
    width: 80%;
    max-width: 80rem;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
}
.compartir__iframe {
    width: 75%;
    padding: .5rem;
}
.compartir__iframe > iframe {
    width: 100%;
    border: 2px solid white;
    /*height: 0;
    padding-top: 40%;*/
}
.compartir__iframe-code {
    width: 25%;
    padding: .5rem;
}
.compartir__code-title {
    color: white;
    margin-bottom: .5rem;
}
.compartir__iframe-code > textarea {
    width: 100%;
    min-height: 10rem;
}


/* QR */
.compartir__qr {
  position: relative;
  float: left;
  margin-left: auto;
  margin-right: auto;
}
.compartir__qr  svg {
  /* Parece k non podemos cambiar o fill e o stroke */
  max-width: 80%;
}
.compartir__qr  canvas {
  width: 300px !important;
  height: auto !important;
  background: white;
  padding: 20px;
  max-width: 80%;
}
.compartir__qr-hover {
  position: absolute;
  background: rgba(0, 0, 0, .5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: var(--color-principal);
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  cursor: pointer;
}
.compartir__qr-hover:hover {
  opacity: 1;
}
.compartir__qr-label {
  font-size: 1.2rem;
  font-weight: bold;
}

@media (max-width: 767px) {
    .compartir__iframe-wrapper {
        flex-direction: column;
    }
    .compartir__iframe,
    .compartir__iframe-code {
      width: 100%;
    }

    .compartir__qr  svg {
      max-width: 50%;
    }
}
