.card-body {
  padding: 0.5em 1em;
}

.card-lista {
  margin-bottom: 10px;
  line-height: 1em;
}

.card-lista__link {
  color: var(--color_texto_secundario);
}

.card-lista__link:hover {
  text-decoration: none;
  color: var(--color_texto_secundario_hover);
}

.card-lista__imagen {
  width: 100%;
  margin-bottom: 15px;
}

.card-lista > .card-title {
  text-align: left;
  margin: 15px;
  background-color: var(--color-secundario);
  color: #ffffff;
  padding: 2px 10px;
}

.card-lista > .card-footer {
  font-size: 14px;
  text-transform: initial
}

.card-lista__fila {
  color: var(--color_texto_secundario);
  margin-bottom: 0.5em;
  padding-bottom: 0.5em;
  border-bottom: 1px dashed var(--color_texto_secundario);
}

.card-lista__datos > div:last-child .card-lista__fila {
  border-bottom: none;
}

.card-lista__fila:hover {
  text-decoration: none;
  color: var(--color_texto_secundario_hover);
}

.card-lista__datos {
  margin-bottom: 0;
}

.card-lista__distancia {
  text-transform: initial;
}

.card-lista .rec-list-canal__icon {
  width: 30px;
  height: 30px;
  display: table;
}

.rec-list-canal__nombre-proyecto {
  font-size: .8em;
}
