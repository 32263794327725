.icono-punto-kilometrico.con-servicios i.icon--text{
  font-size: 15px;
  border: 1px solid #fc0;
  border-radius: 13px;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icono-punto-kilometrico.con-servicios.kmdiv5 i.icon--text{
  border: 1px solid #111111;
}
