
.mapa-modal {
    /*
    flex: 1;
    align-self: center;
    */
    background: var(--color-secundario);
    border-radius: 10px;
    padding: 1em;
    color: var(--color-terciario);
    width: 100%;
    min-width: 300px;
    max-width: 800px;
    /* margin: 0 auto; */
}
.scroll-vertical > .mapa-modal, .scroll-vertical--osrcuro .mapa-modal {
    height: auto;
}
.mapa-submodal {
    /*overflow: hidden;*/
    font-size: 1.2em;
    background-color: transparent;
}

.mapa-submodal__head {
    background: var(--color-principal);
    border-radius: 6px;
    line-height: unset;
    font-size: 1.3em;
}
.mapa-submodal__titulo {
    color: var(--color-secundario);
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: unset;
    text-align: left;
    padding-left: 0.6em;
}
.mapa-modal__body{
  overflow-y: auto;
}

.overflow-hidden .mapa-submodal__body {
  overflow: hidden;
}

.mapa-submodal__close {

}
.mapa-submodal__close button {
    border: none;
    background: transparent;
    color: var(--color-secundario);
    font-weight: bold;
    font-size: 1em;
}

.mapa-submodal__lista {
    text-align: left;
}

.mapa-submodal__lista--pdis {
    list-style: none;
    text-align: center;
    padding: 0;
    margin: 0;
}

.mapa-submodal__lista--pdis {
  overflow-y: auto;
  height: calc(100% - 55px);
  padding-right: 1rem;
}

.mapa-submodal__lista--pdis > li {
    border-bottom: 1px dashed var(--color-terciario);
    padding: 5px;
}
/*

.mapa-submodal {
    max-height: calc(96vh - 330px);
    overflow-y: auto;
}

@media (min-width: 1024px) {
    .mapa-submodal {
        max-height: calc(96vh - 370px);
    }
}

@media (min-width: 1900px) {
    .mapa-submodal {
        max-height: calc(96vh - 410px);
    }
}
*/

.mapa-submodal__esquema {
    list-style: none;
}
.recorrido-list__esquema--vertical {

}

.recorrido-list__esquema--vertical li {
    display: block;
}
