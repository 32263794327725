.heightgraph {
  position: absolute;
  margin: 0 35vw;
}

.leaflet-right .leaflet-control.heightgraph {
  margin-top: 15px;
}

.heightgraph-container {
  background-color: rgba(26, 26, 26, .5);
  color: #fff;
  border-radius: .25em .25em 0 0;
}

.heightgraph-container .grid {
  display: none;
}

.heightgraph-container > text {
  display: none;
}

.heightgraph-container .tick text {
  fill: #fff;
}

.heightgraph-container .domain {
  stroke: #fff;
  stroke-width: 3px;
}

.heightgraph-container .lineSelection {
  display: none;
}

.heightgraph-container .legend {
  display: none;
}

.heightgraph-container .legend-hover {
  display: none;
}

.heightgraph-container rect {
  pointer-events: none !important;
}

@media (max-width: 768px) {
  .heightgraph {
    margin-bottom: 60px;
    margin: 0 5vw;
  }
}
