.contactos__btn-mas {
  bottom: 2rem;
  right: 2rem;
  height: 70px;
  width: 70px;
}

.contactos h3 {
  border-bottom-width: 2px;
  border-bottom-style: dotted;
}

.contactos ul {
  padding: 0;
  list-style: none;
}

.background-dark .fa-circle {
  color: var(--color-gris);
}

.contactos button {
  border: 1px solid var(--color-principal);
}

.contacto_nombre {
  font-size: 1.3em;
}
