.modal-descripcion__resumen {
  background-color: var(--color-principal);
  color: var(--color-secundario);
}

.modal-descripcion__lista-ubicaciones > li {
  color: var(--color-principal);
  background-color: var(--color-secundario);
  border: 1px dashed var(--color-principal);
}

.modal-descripcion__icono-bloques {
  color: var(--color-secundario);
  background-color: var(--color-principal);
  font-size: 24px;
  width: 45px;
  height: 45px;
}

.modal-descripcion__descripcion {
  white-space: pre-line;
}
