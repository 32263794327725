
/* Poño esto aquí para asegurarme de que se sobrescriben os cambios cos css externos ...*/



/* esto copiado do traballo de marcos na administración de proyectos */

@media (max-width: 767px) { #idiomas .idioma .texto { display: none; } }

.boton-filtros { padding: 0px; }

.boton-filtros > i { font-size: 1.2em; padding-top: 4px; }

.boton-filtros > .texto-boton { font-size: 0.6em; line-height: 1.1em; }

.herramientas-busqueda .col-form-label { padding-top: 0px; padding-bottom: 0px; }

.herramientas-busqueda .form-group { background-color: #1A1A1A; color: #fff; border-radius: 4px; padding: 2px 14px 9px; text-transform: uppercase; }

.herramientas-busqueda .form-control { height: 1.7em; padding: 0 0.2em; }



.filtros {
    position: fixed;
    top: 15vh;
    background-color: #fc0;
    color: #1a1a1a;
    right: -900px;
    z-index: 1030;
    border-left: 1px solid #1a1a1a;
    border-top: 1px solid #1a1a1a;
    border-bottom: 1px solid #1a1a1a;
    border-radius: 11px 0 0 10px;
    padding: 1em;
    transition: right .4s ease;
    -webkit-box-shadow: -5px 7px 6px -1px rgba(0,0,0,.33);
    -moz-box-shadow: -5px 7px 6px -1px rgba(0,0,0,.33);
    box-shadow: -5px 7px 6px -1px rgba(0,0,0,.33);
    font-family: var(--font-family);
}
.filtros.mostrar {
    right: 0px;
}

.filtros .scroll-zone {
    max-height: 70vh;
    overflow: auto;
    padding: 0.6em;
}

.filtros .form-inline .form-control { border-radius: 5px; }

.filtros h4 { border-bottom: 1px dashed #000; text-transform: uppercase; font-weight: bold; }

.filtros h5 { font-weight: bold; padding-top: 0.5em; }

.filtros .close-window { display: block; float: right; cursor: pointer; }

.filtros label.col-form-label { font-weight: bold; }

.filtros .DayPickerInput {
  max-width: 34vw;
}

.filtros .form-group {
  width: 100%;
}

.filtro-distancia .css-i35pig-Slider .slider-tooltip-visible {
  padding: 0 5px;
}

.filtro-distancia .css-i35pig-Slider {
  margin-right: 5px;
}

.tooltip-filtro {
  font-size: 0.8em;
}
/*
.form-row .ais-RefinementList {
    display: block;
}
*/

.ais-SortBy {

}
.ais-SortBy-select {
    width: 100%;
}

@media (max-width: 575px) {

  .visor-publico--buscar .filtros {
    font-size: 3.4vw;
  }
  .visor-publico--buscar .filtros .DayPickerInput input {
    height: 26px;
    padding: 5px;
    font-size: 3.2vw;
  }
  .visor-publico--buscar .filtros label {
    margin: 1vw 0;
  }
  .visor-publico--buscar .filtros li {
    min-height: unset;
  }

  .visor-publico--buscar .filtros .form-group {
    margin-bottom: 0;
  }
}
