.info-esquema {
  position: absolute;
  width: 30vw;
  margin: calc(10vh + 5px) 35vw 0 35vw;
}

.info-esquema--sin-perfil {
  margin: 5px 35vw;
}

.info-esquema .card {
  border-radius: 0 0 .25rem .25rem;
}

.info-esquema .card-header {
  padding: 5px 10px;
  border-bottom: 0;
  border-radius: 0 0 .25rem .25rem;
}

.info-esquema__dist{
  float: right;
}

.info-esquema h4 {
  font-size: 1rem;
  font-weight: 600;
  opacity: 1;
  margin-bottom: .2rem;
}

.info-esquema h6 {
  font-size: .8rem;
  opacity: 1;
}

.info-esquema__sector-icono > svg{
  width: .7rem;
  height: .7rem;
}

.info-esquema__background{
  position: absolute;
  height: 100%;
  opacity: 1;
  border: none;
}

@media (max-width: 768px) {
  .info-esquema {
    width: 90vw;
    margin: calc(15vh + 5px) 5vw 0 5vw;
  }
}
