@import "../variables.css";

.navbar-dark.bg-dark {
    background-color: var(--color_fondo) !important;
}

.navbar-dark .navbar-toggler {
    background-color: var(--color_fondo_secundario)
}

.navbar-dark .navbar-toggler-icon {

    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(9, 9, 9, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}

/* Copiados de estilo backend marcos */

.nav-link-icon { text-align: center; }

.nav-link-icon > i,
.nav-link-icon .nav-link > i,
.nav-link-icon svg
{ color: var(--color-principal); }

.nav-link .texto {
    color: var(--color-secundario-opuesto);
    overflow: hidden;
    text-overflow: ellipsis;
}

/* .nav-link-icon > div { font-size: .8em; } */
div.nav-link {
    padding: 0;
}

.portada .dropdown-menu {
    background-color: var(--color-principal);
    color: var(--color-principal-opuesto);
    border-radius: 5px;
}

.portada .dropdown-menu a{
    color: var(--color-secundario);
    border-top: 1px dotted var(--color-secundario);
}

.portada .dropdown .dropdown-divider {
    border-top: 1px solid var(--color-secundario);
}

.home__list-language {
  border-top: 1px dotted var(--color-principal);
}

.home__notificaciones {
  color: var(--color-principal);
}

.disabled .home__notificaciones {
  color: var(--color-gris-oscuro);
}

.home__badge-notificacion {
  top: .5rem;
  right: 2.7em;
}

@media (min-width: 768px) {
  .nav-link .texto {
    font-size: 0.7em;
  }
  .nav-item {
    width: 62px;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-left: 2px;
    padding-right: 2px;
  }
}

@media (max-width: 767px) {
    .nav-link .fa, .nav-link .fas { display: none; }
    .nav-link .texto { width: unset;}
    .navbar li .nav-link, .navbar li .login__pill-item > a { border-bottom: 1px dashed #fff; }
    .navbar-nav .nav-link.dropdown { border-bottom: 1px dashed #FFFFFF; }
    .navbar-nav form .nav-link.dropdown-toggle{ border-bottom: none;}
    .navbar li:first-child .nav-link, .navbar li:first-child .login__pill-item > a { padding-top: 1em; }
    .navbar li:last-child .nav-link, .navbar li:last-child .login__pill-item > a { border-bottom: none; }
    .home__badge-notificacion { top: 0.7rem; right: unset;}
    .dropdown-menu { border-radius: 0; text-align: center;}
    .dropdown-menu a {padding-right: 1.5rem;}
    .home__badge-notificacion-mobile { right: 1.5em;}
    .dropdown-toggle::after { display: none; }
}
