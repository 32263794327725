@import "../../variables.css";

.top-bar {
    background: var(--color_fondo);
    color: var(--color_fondo_secundario);
    height: var(--top-bar-height);
    display: grid;
    grid-template-columns: calc(2 * var(--top-bar-height)) auto calc(2 * var(--top-bar-height));
    font-size: 22px;
    text-align: center;
    line-height: var(--top-bar-height);
    font-weight: bold;
}
.top-bar__left {
    grid-row: 1;
    grid-column: 1;
    text-align: left;
    padding-left: 20px;
}

.top-bar__pantalla-completa {
    margin-top: -5px;
    margin-right: 4px;
    font-size: 13px;
}

.top-bar__compartir {
  font-size: 11px;
  margin-top: -5px;
  margin-right: 4px;
}

.top-bar__center {
    color: var(--color_fondo_secundario);
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    grid-row: 1;
    grid-column: 2;
}

.top-bar__center-rows {
    display: grid;
    grid-template-rows: 35% 30% 35%; /* proyecto, recorrido, esquema */
}
.top-bar__titulo {
    line-height: calc(var(--top-bar-height) / 3);
    font-size: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.top-bar__subtitulo {
    color: white;
    line-height: calc(var(--top-bar-height) / 3);
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.top-bar__esquema {
    color: white;
    line-height: calc(var(--top-bar-height) / 3);
    font-size: 12px;
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
}
.top-bar__esquema li {
    display: inline;
    margin-left: 1em;
}
.top-bar__barrita {
  width: 2rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  height: .8rem;
  border-radius: .3em;
  font-size: .9em;
}
.top-bar__barrita--sector {
  width: auto;
  height: auto;
  color: black;
  line-height: 1;
  padding: 1px 3px;
  font-weight: normal;
}

@media (max-width: 480px) {
    .top-bar__esquema li {
        font-weight: 200;
        margin-left: 0.1vw;
        font-size: 2.8vw;
    }
    .top-bar__barrita {
        width: 4vw;
        height: 1.8vw;
        margin-right: 1vw;
        border-radius: 3px;
    }
    .top-bar__barrita--sector {
        width: auto;
        height: auto;
        line-height: 1;
        padding: 1px 3px;
    }

}

.top-bar__right {
    grid-row: 1;
    grid-column: 3;
    font-size: 1.4em;
    text-align: right;
    padding-right: .5em;
}
.top-bar__right > a {
    color: var(--color_fondo_secundario);
}
.top-bar__right > a:hover,
.top-bar__right > a:active,
.top-bar__right > a:focus {
    color: var(--color_fondo_secundario);
    text-decoration: none;
}

.top-bar__desplegar {
  width: 100%;
  height: 10px;
  color: white;
  position: absolute;
  margin-top: var(--top-bar-height);
  z-index: 9999;
  background-color: var(--color-secundario);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .8em;
}

.top-bar__icono-mapa {
  width: 35px;
}

.top-bar__check-recorrido {
  height: 17px;
  margin-top: 2px;
  vertical-align: baseline;
}
