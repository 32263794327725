.pdi-focused {
  width: 280px;
  background-color: var(--color-principal);
  color: var(--color-secundario);
}

.pdi-focused__servicio {
  width: 30px;
  height: 30px;
  font-size: 18px;
}

.pdi-focused__descripcion {
  text-transform: initial;
  font-size: 1.1em;
}

.pdi-focused__btn {
  background-color: var(--color-secundario);
  color: var(--color-principal);
}

.pdi-focused__btn:hover,
.pdi-focused__btn:active {
  color: var(--color-principal);
}

a.pdi-focused__btn {
  color: var(--color-principal);
}

.pdi-focused__cerrar {
  right: .75rem;
  top: .5rem;
  font-size: 1.4em;
  background-color: transparent;
  border: none;
}

.pdi-focused__cerrar:hover,
.pdi-focused__cerrar:active,
.pdi-focused__cerrar:focus {
  outline: none;
}

.pdi-focused__btn--footer {
  font-size: 1.2em;
}

.pdi-focused__color {
  width: 30px;
  height: 8px;
}

.pdi-focused__avatar-user {
  width: 3em;
  max-height: 3em;
  object-fit: cover;
  border: 2px solid var(--color-secundario);
  background-color: #FFFFFF;
}

.pdi-focused__user-item {
  text-transform: none;
}
