:root {
  --canal-cabecera__tamaño: 250px;
}

.canal-cabecera {
  height: calc(var(--canal-cabecera__tamaño) + 4vw);
  width: 100%;
  z-index: 2;
  position: relative;
}

.canal-cabecera__imagen {
  height: calc(var(--canal-cabecera__tamaño) + 4vw);
  position: absolute;
}

.canal-cabecera__fondo {
  filter: blur(8px);
  -webkit-filter: blur(8px);
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.canal-subcabecera {
  width: 100%;
  background-color: var(--color_fondo);
  z-index: 10;
  position: relative;
  padding: 20px 60px;
  display: inline-flex;
}

.canal-subcabecera__titulo {
  margin-left: 25px;
  display: inline-block;
  color: var(--color_texto);
}

.canal-subcabecera__titulo h2{
  margin: 0;
}

.canal-subcabecera__avatar {
  width: 7vw;
  height: 7vw;
  max-height: 122px;
  max-width: 122px;
  min-height: 80px;
  min-width: 80px;
  object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color_texto);
  border: 7px solid #fff;
}

.canal-subcabecera__avatar > img {
  max-width: 165px;
  max-height: 165px;
  display: block;
  object-fit: cover;
  overflow: hidden;
}

.canal-tabs-container {
  width: 100%;
}

.canal-tabs-container > nav {
  justify-content: center;
  background-color: var(--color-secundario);
}

.canal-tabs-container > nav > .nav-link{
  color: var(--color_texto);
  border: none;
}

.canal-tabs-container > nav > .nav-link.active{
  background-color: var(--color-principal);
}

.canal-tabs__container-tab {
  margin-top: 15px;
}

.canal-tabs__recorrido-destacado {
  width: 100%;
  height: 400px;
}

@media (max-width: 640px) {
  .canal-subcabecera {
    padding: 20px 20px;
  }

  .canal-subcabecera__titulo {
    margin-left: 0;
    display: block;
    width: 100%;
    margin-top: 30px;
  }

  .canal-subcabecera__avatar {
    width: 80px;
    height: 80px;
    display: flex;
    position: absolute;
    top: -40px;
  }
}

/** Pure CSS Masonry Layout **/

.masonry {
  columns: 300px;
  column-gap: 25px;
}

.masonry .article {
  margin-bottom: 25px;
  page-break-inside: avoid;
  display: grid;
}

/** /Pure CSS Masonry Layout **/
