@import "../../variables.css";

.recorrido-list {
    margin: 0;
    list-style: none;
    padding: 0;
    /*height: 100%;*/
    overflow: auto;
    display: block;
    margin: 0 auto;
}

.recorrido-list a:hover {
  text-decoration: none;
  color: var(--color-gris-oscuro);
}

.recorrido-list > li {
    padding: 25px 0;
    display: block;
}

.recorrido-list li a{
  max-width: 500px;
  display: block;
  margin: 0 auto;
}

.recorrido-list > li:nth-child(even) {
  background-color: #ffefc9;
}

.recorrido-list__label {
    text-transform: uppercase;
    display: block;
    text-align: center;
    color: white;
    margin: 0;
    font-size: 1.3em;
    font-weight: bold;
    padding: 0;
    line-height: 1.1em;
}
.recorrido-list__label,
.recorrido-list__label:hover,
.recorrido-list__label:active,
.recorrido-list__label:focus {
    color: white;
    text-decoration: none;
}

.recorrido-list__sectores {
    list-style: none;
    padding: 0;
}

.recorrido-list__sectores > li {
    text-align: center;
}

.recorrido-list__miniatura {
  float: left;
  position: absolute;
  display: block;
}

.recorrido-list__recorrido-nombre {
  background-color: rgba(255, 255 ,255 , 0.7);
  padding: 2px 7px;
  font-weight: bold;
  font-size: 1.2em;
}

.recorrido-list__trayecto {
  width: 35px;
  float: left;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  margin: 5px;
  font-weight: bold;
}

.recorrido-list__captura {
  max-width: 100%;
  height: 225px;
  background-size: cover;
  background-position: center;
  background-color: #c1d1c1;
}

.recorrido-list__deporte_icono {
  width: 55px;
  max-height: 55px;
  background-color: var(--color-secundario);
  color: var(--color-principal);
  padding: 1px;
  float: left;
  margin-right: 5px;
}

.recorrido-list__deporte_icono_default {
  font-size: 2em;
  width: 55px;
  height: 55px;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
}

.recorrido-list__datos {
  text-transform: none;
  margin-top: 7px;
}

.recorrido-list__datos_abajo,
.recorrido-list__datos_arriba {
  display: flex;
  justify-content: space-around;
}

.recorrido-list__datos_abajo {
  color: var(--color-principal);
  background-color: var(--color-secundario);
  margin-top: 7px;
}

.recorrido-list__deporte_icono a{
  color: var(--color-principal);
}

.recorrido-list__esquema {
  display: flex;
  justify-content: center;
  background-color: var(--color-secundario);
  text-transform: initial;
}
/*
.recorrido-list__esquema > li > span {
    width: 20px;
    display: inline-block;
    height: .8em;
    border-radius: .4em;
    margin-right: .5em;
}
*/
.recorrido-list__sectores {
    list-style: none;
    padding: 0;
}

.recorrido-list__sectores-li {
    font-size: 16px;
    line-height: 1em;
    margin: 6px .5em;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.recorrido-list__icono {
    display: inline-block;
    color: #22D923;
    width: 22px;
    height: 22px;
    margin-right: 0.5em;
    border-radius: 4px;
    overflow: hidden;
    padding: 2px;
}
.recorrido-list__icono > svg {
    height: 17px;
    width: 17px;
}

.recorrido-list__sector-nombre {
    background-color: #22D923;
    color: #111111;
    font-size: 14px;
    display: inline-block;
    padding: 3px 4px;
    border-radius: .4em;
    margin-right: .5em;
}

.recorrido-list__sectores-nombre {
    color: #111111;
}

.recorrido-list__sectores-distancia {
    color: #FFFFFF;
}

.recorrido-list-dificultad {
  width: 0.8em;
  height: 0.8em;
  display: inline-block;
  border-radius: 0.5em;
}

.recorrido-list-dificultad.muydificil {
  color: #980239 !important;
}

.recorrido-list-dificultad.dificil {
  color: #ff0000 !important;
}

.recorrido-list-dificultad.moderado {
  color: #f88800 !important;
}

.recorrido-list-dificultad.facil {
  color: #84b711 !important;
}

.recorrido-list-dificultad.muyfacil {
  color: #1d715d !important;
}

.recorrido-list__check {
  width: 12px;
  vertical-align: baseline;
}

@media (max-width: 500px) {
  .recorrido-list__datos {
    text-transform: none;
    margin-top: 7px;
    margin-left: 0.8em;
    margin-right: 0.8em;
  }
}

@media (max-width: 400px) {
  .recorrido-list__datos {
    font-size: 4vw;
  }
}

@media (max-width: 359px) {
  .recorrido-list__deporte_icono {
    width: 14vw;
    max-height: 14vw;
  }
  .recorrido-list__deporte_icono_default {
    font-size: 2em;
    width: 14vw;
    height: 14vw;
  }
}
