@import url(https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@300;600;700&display=swap);
@import url(https://cdn.jsdelivr.net/npm/instantsearch.css@7.3.1/themes/reset.css);
@import url(https://cdn.jsdelivr.net/npm/instantsearch.css@7.3.1/themes/algolia.css);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


:root {
    --color-principal: #FFCC00;
    --color-secundario: #1A1A1A;
    --color-terciario: #FFFFFF;
    --color-gris: #A9A9A9;
    --color-gris-oscuro: #5e5e5e;
    --color-principal-opuesto: --color-secundario;
    --color-secundario-opuesto: #FFFFFF;
    --color-rojo-destacado: #ff4444;

    /*--color-gris: #A3A3A3;*/

    --top-bar-height: 6px;
    --footer-height: 100px;
}

@media (min-width:1900px) {
    :root {
        --footer-height: 120px;
    }
}

:root{
    --color_fondo: #1a1a1a;
    --color_fondo_hover: #000;
    --color_fondo_mas_claro: #333;
    --color_fondo_secundario: #ffcc00;
    --color_fondo_secundario_hover: #f3c000;
    --color_fondo_seleccionado: #626262;
    --color_texto: #f3f3f3;
    --color_texto_hover: #fff;
    --color_texto_sobre_fondo: #ffcc00;
    --color_texto_sobre_fondo_hover: #f3c000;
    --color_texto_secundario: #444;
    --color_texto_secundario_hover: #000000;
    --color_fondo_modal: rgba(243, 243, 243, 1);
    --color_fondo_modal_secundario: #a9a9a9;
    --color_fondo_modal_secundario_hover: #919191;
    --color_btn_shadow: rgba(46, 46, 46, .5);
    --color_btn_shadow_secundario: rgba(243, 204, 0, .5);
    --font-family: 'Barlow Semi Condensed', Roboto, Arial, 'sans-serif';
}

body {
    font-family: 'Barlow Semi Condensed', Roboto, Arial, 'sans-serif';
    font-family: var(--font-family);
    text-transform: uppercase;
}

button {
    text-transform: uppercase;
}

.btn-black {
  background: #1A1A1A;
  background: var(--color-secundario);
  color: #FFCC00;
  color: var(--color-principal);
}
.btn-black:hover,.btn-black:active, .btn-black:focus {
  color: #FFCC00;
  color: var(--color-principal);

}

p {
    text-transform: none;
}

a {
    color: #1A1A1A;
    color: var(--color-secundario);
    transition: color 200ms linear;
}

a:hover {
  color: #6C6C6C;
  text-decoration: none;
}

.modal-content {
    border-radius: 10px;
}

.fondo-carga{
    background-color: #ffcc00;
    background-color: var(--color_fondo_secundario);
}


img.logo {
    width: 270px;
    max-width: 65vw;
}

.scroll-vertical {
    overflow-y: scroll;
    scrollbar-color: #ffcc00 #A9A9A9;
    scrollbar-color: var(--color_fondo_secundario) var(--color-gris);
}
.scroll-vertical::-webkit-scrollbar {
    width: 12px;
}

.scroll-vertical::-webkit-scrollbar-track {
}

.scroll-vertical::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #ffcc00;
    background: var(--color_fondo_secundario)
}
.scroll-vertical::-webkit-scrollbar-track-piece  {
    background: #A9A9A9;
    background: var(--color-gris);
}

.scroll-vertical--oscuro {
    overflow-y: scroll;
    scrollbar-color: #1a1a1a #A9A9A9;
    scrollbar-color: var(--color_fondo) var(--color-gris);
}
.scroll-vertical--oscuro::-webkit-scrollbar {
    width: 12px;
}

.scroll-vertical--oscuro::-webkit-scrollbar-track {
}

.scroll-vertical--oscuro::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #1a1a1a;
    background: var(--color_fondo)
}
.scroll-vertical--oscuro::-webkit-scrollbar-track-piece  {
    background: #A9A9A9;
    background: var(--color-gris);
}



.main-grid--proyecto, .main-grid--proyecto-3, .main-grid--proyecto-4 {
    /*height: 100vh; */
    width: 100vw;

    /* https://developers.google.com/web/updates/2016/12/url-bar-resizing */
    position: fixed;
    height: 100%;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 6px 6px auto 100px;
    grid-template-rows: var(--top-bar-height) var(--top-bar-height) auto var(--footer-height);
    /*overflow-y: auto*/
}

.main-grid--proyecto-3 {
    grid-template-rows: 6px auto 100px;
    grid-template-rows: var(--top-bar-height) auto var(--footer-height);
}

.main-grid--recorrido {
    /*height: 100vh;*/
    width: 100vw;

    /* https://developers.google.com/web/updates/2016/12/url-bar-resizing */
    position: fixed;
    height: 100%;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 6px 1fr;
    grid-template-rows: var(--top-bar-height) 1fr;
    /*overflow-y: auto*/
}

.main-grid--recorrido--privado {
  height: calc(100% - 50px);
}

.visor-publico {
    height: 100vh;
    width: 100vw;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 6px 1fr;
    grid-template-rows: var(--top-bar-height) 1fr;
    font-family: 'Barlow Semi Condensed', Roboto, Arial, 'sans-serif';
    font-family: var(--font-family);
    /*overflow-y: auto*/
}

.visor-publico .css-15g0dol-Base{
    font-family: 'Barlow Semi Condensed', Roboto, Arial, 'sans-serif';
    font-family: var(--font-family);
}


.boton-visor {
    font-family: 'Barlow Semi Condensed', Roboto, Arial, 'sans-serif';
    width: 57px;
    height: 57px;
    display: inline-block;
    background-color: #FFCC00;
    background-color: var(--color-principal);
    border-radius: 5px !important;
    text-align: center;
    margin: 15px;
    overflow: hidden;
    padding: 0;
    transition: background-color 0.3s ease;
}

.boton-visor:hover,
.boton-visor:active,
.boton-visor:focus{
    background-color: #FFFFFF;
    background-color: var(--color-terciario);
    border: 1px solid #1A1A1A;
    border: 1px solid var(--color-secundario);
}

.boton-visor a {
    color: #1a1a1a;
    color: var(--color_fondo)
}
.boton-visor button,
.boton-visor > a {
    color: #1a1a1a;
    color: var(--color_fondo);
    background-color: transparent;
    border: none;
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.boton-visor .fas {
    margin-bottom: 5px;
    display: block;
}

.boton-visor > a:hover,
.boton-visor > a:active,
.boton-visor > a:focus{
    text-decoration: none;
}

.boton-visor.boton-destacado{
    background-color: #1A1A1A;
    background-color: var(--color-secundario);
    color: #FFCC00;
    color: var(--color-principal);
    border: 1px solid #FFCC00;
    border: 1px solid var(--color-principal);
    transition: all 0.3s ease;
}

.boton-visor.boton-destacado:hover,
.boton-visor.boton-destacado:active,
.boton-visor.boton-destacado:focus{
    background-color: #FFFFFF;
    background-color: var(--color-terciario);
    color: #1A1A1A;
    color: var(--color-secundario);
    border: 1px solid #FFCC00;
    border: 1px solid var(--color-principal);
}

.boton-visor.boton-destacado:hover a,
.boton-visor.boton-destacado:active a,
.boton-visor.boton-destacado:focus a{
    color: #1A1A1A;
    color: var(--color-secundario);
}

.boton-visor.boton-destacado a{
    color: #FFCC00;
    color: var(--color-principal);
}

.boton-visor .btn__texto {
    text-transform: uppercase;
    font-size: 10px;
    line-height: 1;
    display: block;
}

.boton-visor--small {
    width: 54px;
    height: 54px;
    margin: 8px 8px;
    display: table-cell;
    vertical-align: middle;
    font-size: 1.2rem;
    padding: 2px;
}

.boton-visor--small .btn__texto {
    text-transform: uppercase;
    font-size:  0.7em;
    line-height: 1;
}

@media (min-width: 1024px) {
    .boton-visor--small {
        width: 80px;
        height: 80px;
        font-size: 1.7rem;
    }
}

@media (min-width: 1900px) {
    .boton-visor--small {
        width: 95px;
        height: 95px;
        font-size: 2rem;
        padding: 5px;
    }
}

.boton-visor__group {
    padding: 0;
    position: center;
}

ul.boton-visor__group {
    margin-bottom: 0.5rem;
}

.sub-heramientas ul.boton-visor__group {
    margin-bottom: 0;
}

.sub-heramientas .boton-visor--small {
    margin-bottom: 0;
}

.sub-heramientas .boton-visor{
    background-color: #1A1A1A;
    background-color: var(--color-secundario);
    color: #FFCC00;
    color: var(--color-principal);
}

.sub-heramientas .boton-visor:hover{
    background-color: #FFFFFF;
    background-color: var(--color-terciario);
}

.sub-heramientas .boton-visor button,
.sub-heramientas .boton-visor a{
    color: #FFCC00;
    color: var(--color-principal);
}

.sub-heramientas .boton-visor:hover button,
.sub-heramientas .boton-visor:hover a{
    color: #1A1A1A;
    color: var(--color-secundario);
}

.boton-visor .fa-stack {
    font-size: 0.6em;
    margin-top: -0.5em;
}

.boton-visor .fa-stack-2x {
    opacity: 0.7;
}


/* Nova estrutura para evitar problemas coa barra de direccións en android */

.visor__layout {

}
.visor__layout .top-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99999;
}
.visor__layout .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

}
.visor__main {
    padding-top: 6px;
    padding-top: var(--top-bar-height);
    padding-bottom: 100px;
    padding-bottom: var(--footer-height);
}
.visor__main--no-footer {
    padding-bottom: 0;
}

.visor__main--perfil {
    padding-top: 0;
    padding-bottom: 0;
}

.visor__main--perfil .perfil__grafico-sizer {
    /* En este caso especial o gráfico debe ocupar o 100% da pantalla */
    height: 100vw;
    padding-top: 0;
}

@media only screen and (max-width: 700px) and (orientation:portrait) {
    .visor__layout--force-landscape {

        height: 100vw;
        width: 100vh;
        overflow: hidden;
        -webkit-transform-origin: top left;
                transform-origin: top left;
        /* Orden de aplicar transformacións: de dereita a esquerda*/
        -webkit-transform: translateX(100vw) rotate(90deg) ;
                transform: translateX(100vw) rotate(90deg) ;
    }

}


@media only screen and (orientation:landscape) {
    .visor__layout--force-landscape {
    }
    .visor__main--perfil .perfil__grafico-sizer {
        height: 100vh;
    }
}



/**
* Copiado das cousas do gráfico que tiña para a portada...
* O problema e que como non usou BEM e puxo os nombres das clases ó chou
* agora peganse uns estilos cos outros...
*/
.background-dark {
    background-color: #1a1a1a;
    color: #fff;
}
.background-light {
    background-color: #fff;
    color: #1a1a1a;
}
.background-brandcolor {
  background-color: #FFCC00;
  background-color: var(--color-principal);
  color: #1a1a1a;
}

/* Portada */

img.logo-portada {
    max-width: 95vw;
    width: 640px;
}

.portada-descripcion,
.portada-info {
    text-transform: none;
    font-size: 1.4em;
    line-height: 1.3em;
}

.portada-info{
    padding: 6em 0;
    flex-direction: column;
}

.portada-info .info-item{
    max-width: 800px;
    display: flex;
    justify-content: space-between;
    padding: 1.3em 0;
    flex-direction: row;
    margin: 0 auto;
}

.portada-info .info-item.info-item-top{
    flex-direction: column;
    padding: 3em 0;
}

.portada-info .info-item .info-contenedor-imagen{
    width: 35%;
}

.portada-info .info-item .info-contenedor-imagen.info-contenedor-imagen-top{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 1.6em;
}

.portada-info .info-item .info-contenedor-imagen .info-imagen {
    width: 100%;
}

.portada-info .info-item .info-contenedor-imagen.info-contenedor-imagen-top .info-imagen{
    width: 70%;
}

.portada-info .info-item .info-texto{
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1.3em;
}

.portada-info .info-item .info-texto.info-texto-top {
    width: 100%;
    text-align: center;
}

.portada-info .info-item .info-texto .info-titulo{
    font-size: 1.4em;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1.1em;
    margin-bottom: 0.2em;
}

@media (max-width: 991px) {
    .portada-info .info-item  {
        max-width: 82vw;
    }
}

@media (max-width: 767px) {
    .portada-info .info-item  {
        max-width: 82vw;
        font-size: 2.8vw;
    }
    .portada-info .info-item .info-contenedor-imagen.info-contenedor-imagen-top .info-imagen{
        width: 95%;
        padding: 1em 0;
    }
}

@media (max-width: 575px) {
    .portada-info .info-item .info-texto,
    .portada-info .info-item .info-contenedor-imagen {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .portada-info .info-item,
    .portada-descripcion {
        justify-content: center;
        flex-direction: column;
        text-align: center;
        font-size: 4vw;
    }
    .portada-info .info-item .info-contenedor-imagen .info-imagen {
        width: 80vw;
        padding: 2em;
    }
    .portada-info .info-item.reverse{
        flex-flow: column-reverse;
    }
}

h2.portada-titular { text-transform: uppercase; font-size: 1.4em; border-bottom: 1px dashed #FFCC00; color: #FFCC00; padding-bottom: 5px; margin: 1.3em 0; }

h2.portada-titular .fas { padding-right: 0.3em; }

.map-card { color: #1A1A1A; background-color: #FFCC00; padding: 3px 9px 9px 9px; border-radius: 6px; text-transform: uppercase; margin-bottom: 30px; }

.map-card-title { max-height: 1.6em; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; font-weight: bold; font-size: 1em; }

.map-card-date { font-size: 0.7em; line-height: 1em; margin-bottom: 5px; }

.btn-amarillo {
    color: #212529;
    background-color: #fc0;
    border-color: #1a1a1a;
}

ul#idiomas { list-style: none; padding-left: 0; }

#idiomas button { background-color: transparent; color: #fff; border: none; text-transform: uppercase; }

#idiomas .bandera { width: 25px; margin-right: 0.3em; }

@media (max-width: 767px) { #idiomas .idioma .texto { display: none; } }


/* Cabecera */

.cabecera {
    background-color: #1a1a1a;
    background-color: var(--color_fondo);
}

.cabecera .titulo {
    color: #f3f3f3;
    color: var(--color_texto);
}

.cabecera .titulo:hover {
    color: #fff;
    color: var(--color_texto_hover);
}

.cabecera .navbar-brand {
    padding-bottom: 1px;
}

.cabecera .navbar-collapse {
    background-color: #1A1A1A;
    background-color: var(--color-secundario);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.cabecera .navbar-nav .btn-cabecera-editor{
    color: #f3f3f3;
    color: var(--color_texto);
}

.cabecera .navbar-nav .btn-cabecera-editor:hover{
    color: #fff;
    color: var(--color_texto_hover);
}

.cabecera .navbar-nav .btn-cabecera-editor:focus{
    color: #fff;
    color: var(--color_texto_hover);
    outline: none;
}

.cabecera .navbar-nav .btn-cabecera-editor-usuarios{
    color: #ffcc00;
    color: var(--color_texto_sobre_fondo);
}

.cabecera .navbar-nav .btn-cabecera-editor-usuarios:hover{
    color: #f3c000;
    color: var(--color_texto_sobre_fondo_hover);
}

.cabecera .navbar-nav .btn-cabecera-editor-usuarios:focus{
    color: #f3c000 !important;
    color: var(--color_texto_sobre_fondo_hover) !important;
    outline: none;
}

.cont-btn-cabecera-edit.show > .nav-link{
    background-color: #333;
    background-color: var(--color_fondo_mas_claro);
}

.cont-btn-cabecera-edit-users{
    border-color: #f3f3f3;
    border-color: var(--color_texto);
}

.editor-barra-izquierda {
    background-color: #333;
    background-color: var(--color_fondo_mas_claro);
}

.editor-acordeon-header {
    background-color: #1a1a1a;
    background-color: var(--color_fondo);
    color: #ffcc00;
    color: var(--color_texto_sobre_fondo);
}

.editor-acordeon-body{
    color: #f3f3f3;
    color: var(--color_texto)
}

.editor-acordeon-body ul > .seleccionado{
    background-color: #626262;
    background-color: var(--color_fondo_seleccionado);
}

.editor-acordeon-body .editor-acordeon-marca-color {
    border: 1px solid #FFFFFF;
}

.subcabecera-editor {
    background-color: #ffcc00;
    background-color: var(--color_fondo_secundario);
}

.btn-toggle-esquema-edit{
    border-right: 1px solid #444 !important;
    border-right: 1px solid var(--color_texto_secundario) !important;
}

.btn-toggle-esquema-edit:hover,
.btn-toggle-esquema-edit:active,
.btn-toggle-esquema-edit:focus{
    border-right: 1px solid #000000 !important;
    border-right: 1px solid var(--color_texto_secundario_hover) !important;
}

.subcabecera-editor-barra-izquierda{
    border-right: 1px solid #444 !important;
    border-right: 1px solid var(--color_texto_secundario) !important;
}

.btn-add {
    background-color: #1a1a1a;
    background-color: var(--color_fondo);
    color: #ffcc00;
    color: var(--color_texto_sobre_fondo);
}

.btn-add:hover,
.btn-add:active,
.btn-add:focus{
    background-color: #000;
    background-color: var(--color_fondo_hover);
    color: #f3c000;
    color: var(--color_texto_sobre_fondo_hover);
}

.ico-nada-anadido{
    color: #ffcc00;
    color: var(--color_texto_sobre_fondo);
}

.txt-nada-anadido{
    color: #ffcc00;
    color: var(--color_texto_sobre_fondo);
}

.diag-editor .modal-content{
    background-color: transparent;
}

.diag-editor .modal-header{
    background-color: #1a1a1a;
    background-color: var(--color_fondo);
    color: #ffcc00;
    color: var(--color_texto_sobre_fondo);
}

.diag-editor .modal-header .close {
    background-color: #f3f3f3;
    background-color: var(--color_texto);
    color: #1a1a1a;
    color: var(--color_fondo);
    opacity: 1;
    border-radius: 6px;
}

.diag-editor .modal-header .close:hover,
.diag-editor .modal-header .close:active,
.diag-editor .modal-header .close:focus {
    background-color: #fff;
    background-color: var(--color_texto_hover);
    color: #000;
    color: var(--color_fondo_hover);
    opacity: 1;
}

.diag-editor .modal-body{
    background-color: rgba(243, 243, 243, 1);
    background-color: var(--color_fondo_modal);
}

.diag-editor .modal-footer{
    background-color: rgba(243, 243, 243, 1);
    background-color: var(--color_fondo_modal);
}

.btn-biblio > button{
    background-color: #1a1a1a;
    background-color: var(--color_fondo);
    color: #f3f3f3;
    color: var(--color_texto);
}

.btn-biblio > button:hover,
.btn-biblio > button:active,
.btn-biblio > button:focus {
    background-color: #000;
    background-color: var(--color_fondo_hover);
    color: #fff;
    color: var(--color_texto_hover);
}

.btn-biblio-servicios > button:hover {
    color: #ffcc00;
    color: var(--color_fondo_secundario);
}

.btn-biblio-servicio-seleccionado > button {
    background-color: #ffcc00;
    background-color: var(--color_fondo_secundario);
    color: #1a1a1a;
    color: var(--color_fondo);
}

.btn-biblio-servicio-seleccionado > button:hover,
.btn-biblio-servicio-seleccionado > button:active,
.btn-biblio-servicio-seleccionado > button:focus {
    background-color: #f3c000;
    background-color: var(--color_fondo_secundario_hover);
    color: #000;
    color: var(--color_fondo_hover);
}


/* Nota do gráfico: Esto está cheo de importants! que non é recomendable usalos,
 como non sei moi ben porque se puxeron nin quen os puxo vou deixalos, porqué é
 un rollo saber para que serven e se se poden borrar, pero organizando ben o css
 non deberían facer falta.*/

.btn-biblio:focus {
    box-shadow: 0 0 0 0.2rem rgba(46, 46, 46, .5) !important;
    box-shadow: 0 0 0 0.2rem var(--color_btn_shadow) !important;
}

.btn-barra-herramientas-editor {
    background-color: #ffcc00;
    background-color: var(--color_fondo_secundario);
    color: #444;
    color: var(--color_texto_secundario);

}

.btn-barra-herramientas-editor:hover,
.btn-barra-herramientas-editor:active,
.btn-barra-herramientas-editor:focus {
    background-color: #1A1A1A !important;
    background-color: var(--color-secundario) !important;
    color: #FFCC00 !important;
    color: var(--color-principal) !important;
}

.btn-barra-herramientas-editor:focus{
    box-shadow: 0 0 0 0.2rem rgba(243, 204, 0, .5) !important;
    box-shadow: 0 0 0 0.2rem var(--color_btn_shadow_secundario) !important;
}

.btn-barra-herramientas-eliminar {
    background-color: #1A1A1A !important;
    background-color: var(--color-secundario) !important;
    color: #FFCC00 !important;
    color: var(--color-principal) !important;
}

.btn-barra-herramientas-eliminar:hover,
.btn-barra-herramientas-eliminar:active,
.btn-barra-herramientas-eliminar:focus {
    background-color: #FFCC00 !important;
    background-color: var(--color-principal) !important;
    color: #1A1A1A !important;
    color: var(--color-secundario) !important;
}

.btn-barra-herramientas-eliminar:focus{
    box-shadow: 0 0 0 0.2rem rgba(46, 46, 46, .5) !important;
    box-shadow: 0 0 0 0.2rem var(--color_btn_shadow) !important;
}

.display-distancias{
    background-color: rgba(243, 243, 243, 1);
    background-color: var(--color_fondo_modal);
    color: #444;
    color: var(--color_texto_secundario)
}

.display-trayecto-crear-recorrido{
    background-color: #1a1a1a;
    background-color: var(--color_fondo);
}

.display-trayecto-crear-recorrido:hover {
    background-color: #000;
    background-color: var(--color_fondo_hover);
}

.display-trayecto-crear-recorrido > .nombre-trayecto {
    color: #f3f3f3;
    color: var(--color_texto);
}

.display-trayecto-crear-recorrido:hover > .nombre-trayecto {
    color: #fff;
    color: var(--color_texto_hover);
}

.display-trayecto-crear-recorrido > .distancia-trayecto {
    color: #444;
    color: var(--color_texto_secundario);
}

.display-trayecto-crear-recorrido:hover > .distancia-trayecto {
    color: #000000;
    color: var(--color_texto_secundario_hover);
}

.contenedor-lista-sectores{
    background-color: #a9a9a9;
    background-color: var(--color_fondo_modal_secundario);
}

.contenedor-lista-sectores.vacia > .msg {
    color: #444;
    color: var(--color_texto_secundario)
}

.contenedor-lista-sectores.is-drag-over{
    background-color: #919191;
    background-color: var(--color_fondo_modal_secundario_hover);
}

.tabla-trayectos-sector > tbody > tr {
    background-color: #1a1a1a;
    background-color: var(--color_fondo);
    color: #f3f3f3;
    color: var(--color_texto);
}

.contenedor-lista-sectores.is-drag-over .tabla-trayectos-sector > tbody > tr {
    border-bottom: 3px solid #919191;
    border-bottom: 3px solid var(--color_fondo_modal_secundario_hover);
}

.tabla-trayectos-sector .titulo-trayecto{
    color: #444;
    color: var(--color_texto_secundario)
}

.tabla-trayectos-sector .basura-trayecto{
    color: #ffcc00;
    color: var(--color_texto_sobre_fondo);
}

.tabla-trayectos-sector .basura-trayecto:hover {
    color: #f3c000;
    color: var(--color_texto_sobre_fondo_hover);
}

.encabezado-sector .titulo {
    color: #444;
    color: var(--color_texto_secundario);
}

.encabezado-sector .titulo.anadir:hover {
    color: #000000;
    color: var(--color_texto_secundario_hover);
}

.display-trayecto-cabecera {
    background-color: #1a1a1a;
    background-color: var(--color_fondo);
}

.display-trayecto-cabecera .nombre-trayecto {
    color: #f3f3f3;
    color: var(--color_texto);
}

.display-trayecto-cabecera .repeticiones-trayecto{
    background-color: #626262;
    background-color: var(--color_fondo_seleccionado);
}

.cabecera .nav-pills .nav-link {
    border-radius: 0;
}

.cabecera .nav-link {
    padding: 0.4rem 1rem;
}

.tabla-organizar-recorridos > tbody > tr {
    background-color: #1a1a1a;
    background-color: var(--color_fondo);
    color: #f3f3f3;
    color: var(--color_texto);
}

.tabla-organizar-recorridos > tbody > tr {
    border-bottom: 3px solid rgba(243, 243, 243, 1);
    border-bottom: 3px solid var(--color_fondo_modal);
}

.tabla-organizar-recorridos > tbody > tr.fila-eliminado {
    background-color: #ff4444;
    background-color: var(--color-rojo-destacado);
}



/* Dropdown */

.dropdown-menu {
  /*
  background-color: var(--color_fondo);
    color: var(--color_texto_sobre_fondo);
   */
    padding: 0;
    border-radius: 6px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    overflow: hidden;
}

.dropdown-menu > a{
  border-top: 1px dotted #1A1A1A !important;
  border-top: 1px dotted var(--color-secundario) !important;
  padding-right: 2.4em;
}

.dropdown-menu > a:first-child{
    border-top: none;
}

.dropdown .dropdown-divider {
    margin: 0;
    border-top: 1px solid #1A1A1A;
    border-top: 1px solid var(--color-secundario);
}

.dropdown-item-recorridos svg {
    margin-right: 5px;
    display: inline-block;
}

.dropdown-menu svg.eliminar-recorrido{
    right: 0;
    position: absolute;
    margin-right: 1em;
}

.dropdown-item-recorridos > .eliminar-recorrido {
    float: right;
}

.cabecera-navitem-nombre {
    max-width: 19em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}


.navbar-nav .nav-link {
  text-align: left;
}
.navbar-nav .nav-link .dropdown-menu {
  background-color: transparent;
}
.navbar-nav .nav-link .dropdown-menu .dropdown-item {
  color: #FFCC00;
  color: var(--color-principal);
  text-align: left;
  padding-left: 20px;
  border-top: 1px dotted #FFCC00 !important;
  border-top: 1px dotted var(--color-principal) !important;
}
.navbar-nav .nav-link .dropdown-toggle::after {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 20px;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  /* transition: all 300s ease-in-out;*/
}
.navbar-nav .nav-link.show .dropdown-menu {
  padding-bottom: 16px;
}
.navbar-nav .nav-link.show .dropdown-toggle .texto {
  color: #FFCC00;
  color: var(--color-principal)
}
.navbar-nav .nav-link.show .dropdown-toggle::after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  color: #FFCC00;
  color: var(--color-principal)
}

@media (min-width: 768px) {
  .navbar .nav-item {
    transition: background-color 300ms ease-in-out;
  }

  .navbar .nav-item:hover,
  .navbar .nav-item.show {
    background: #FFCC00;
    background: var(--color-principal);
    /*color: white;*/
    color: #1A1A1A;
    color: var(--color-secundario);
    z-index: 1001;
  }

  .navbar .nav-item .texto,
  .navbar .nav-item .dropdown-toggle:after,
  .navbar .nav-item i.fas,
  .navbar .nav-item svg {
    transition: color 300ms ease-in-out;
  }

  .navbar .nav-item:hover .texto,
  .navbar .nav-item.show .texto,
  .navbar .nav-item:hover .dropdown-toggle:after,
  .navbar .nav-item.show .dropdown-toggle:after,
  .navbar .nav-item:hover i.fas,
  .navbar .nav-item:hover svg,
  .navbar .nav-item.show i.fas,
  .navbar .nav-item.show svg {
    color: #1A1A1A;
    color: var(--color-secundario);
  }

  .navbar .nav-item.show .dropdown-menu.show {
    background-color: #FFCC00;
    background-color: var(--color-principal);
    color: #1A1A1A;
    color: var(--color-secundario);
    margin: -1px !important;
    border-top: none;
  }

  .navbar-nav .nav-link {
    text-align: center;
  }
  .navbar-nav .nav-link .dropdown-toggle::after {
    position: relative;
    right: auto;
    top: auto;
    -webkit-transform: none;
            transform: none;
  }
  .navbar-nav .nav-link.show .dropdown-toggle .texto {
    color: #1A1A1A;
    color: var(--color-secundario)
  }
  .navbar-nav .nav-link .dropdown-menu .dropdown-item {
    color: #1A1A1A;
    color: var(--color-secundario);
    border-color: #1A1A1A !important;
    border-color: var(--color-secundario) !important;
  }
}


/* Formularios */

.form-control {
    border-radius: 6px;
    border: 1px solid #1a1a1a;
    border: 1px solid var(--color_fondo);
}

label.form-label {
    font-size: 1.1em;
    font-weight: bold;
    margin-bottom: 0.1rem;
}

/* Leaflet */

.leaflet-container {
    font-family: 'Barlow Semi Condensed', Roboto, Arial, 'sans-serif';
}

.leaflet-touch .leaflet-control-attribution {
    text-transform: none;
}

/* Botones */

.btn-primary {
    background-color: #FFCC00;
    background-color: var(--color-principal);
    color: #1A1A1A;
    color: var(--color-secundario);
    border: none;
}

.btn-primary:hover{
    background-color: #FFFFFF;
    background-color: var(--color-terciario);
    color: #1A1A1A;
    color: var(--color-secundario);
}

.btn-secondary {
    background-color: #1A1A1A;
    background-color: var(--color-secundario);
    color: #FFCC00;
    color: var(--color-principal);
    border: none;
}

.btn-secondary:hover{
    background-color: #FFFFFF;
    background-color: var(--color-terciario);
    color: #1A1A1A;
    color: var(--color-secundario);
}

/* Modales */

.modal-title {
    font-weight: bold;
}

.modal-content .rw-multiselect-tag {
    margin: 0 0 6px 6px;
}

/* Dificultad */

.ais-Hits .fa-circle {
    font-size: 0.8em;
}

.facil {
    color: #49b738 !important;
}

.media {
    color: #d1b110 !important;
}

.moderada {
    color: #b77302 !important;
}

.dificil {
    color: #b70000 !important;
}

.muydificil {
    color: #7103b7 !important;
}

.aparece-abajo {
    -webkit-animation-name: aparece-abajo;
            animation-name: aparece-abajo;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-timing-function: ease;
            animation-timing-function: ease;
}

.aparece-arriba {
    -webkit-animation-name: aparece-arriba;
            animation-name: aparece-arriba;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-timing-function: ease;
            animation-timing-function: ease;
}

@-webkit-keyframes aparece-abajo {

    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
                transform: translate3d(0, 100%, 0);
        visibility: visible;
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }

}

@keyframes aparece-abajo {

    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
                transform: translate3d(0, 100%, 0);
        visibility: visible;
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }

}

@-webkit-keyframes aparece-arriba {

    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
                transform: translate3d(0, -100%, 0);
        visibility: visible;
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }

}

@keyframes aparece-arriba {

    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
                transform: translate3d(0, -100%, 0);
        visibility: visible;
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }

}


@media (max-width: 767px) {
    .visor-publico--buscar .container {
        max-width: 88vw;
    }
}

/* Flechas del perfil */

.modal-editor-perfil .flechas {
    position: absolute;
    z-index: 10;
    margin-top: 0.6em;
}


.modal-editor-perfil .flecha-dch {
    right: 0px;
}

.modal-editor-perfil .flecha-izq {
    left: 0px;
}

.fa-rotate-45 {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.fas.fa-pull-bottom {
    margin-bottom: -0.5em;
    bottom: 0px;
}

.table-perfil-resumen .fa-stack {
    width: 1em;
}

.spinner-respuesta-servidor{
    border-color: #ffcc00;
    border-color: var(--color_texto_sobre_fondo);
    border-right-color: transparent;
}


/* Perfiles visor: Datos de la leyenda del perfil */


ul.perfil-data {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

ul.perfil-data li{
    height: 1.8em;
}

ul.perfil-data .icon{
    font-size: 0.8em;
    width: 1.5em;
    display: flex;
    float: left;
    justify-content: center;
    align-items: center;
    padding: 0.6em 1.6em;
}

.fondo-carga {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  align-content: center;
  overflow: hidden;
}

.spinner-carga {
  width: 6rem;
  height: 6rem;
  display: block;
  margin: auto;
}

.spinner-carga-sm {
  width: 2rem;
  height: 2rem;
}

.portada-texto-encargo {
  color: #FFCC00;
  color: var(--color-principal);
  font-size: 1rem;
}



body {
    background: var(--color_fondo_secundario);
}

/* Cargamos esto aquí para poder sobreescribilo nos seguintes compoñentes... */


:root {
    --color-principal: #ffcc00;
    --color-secundario: #1A1A1A;
    --color-terciario: #FFFFFF;
    --color-gris: #A9A9A9;
    --color-gris-oscuro: #5e5e5e;
    --color-principal-opuesto: --color-secundario;
    --color-secundario-opuesto: #FFFFFF;
    --color-rojo-destacado: #ff4444;

    /*--color-gris: #A3A3A3;*/

    --top-bar-height: 60px;
    --footer-height: 100px;
}

@media (min-width:1900px) {
    :root {
        --footer-height: 120px;
    }
}

.navbar-dark.bg-dark {
    background-color: var(--color_fondo) !important;
}

.navbar-dark .navbar-toggler {
    background-color: var(--color_fondo_secundario)
}

.navbar-dark .navbar-toggler-icon {

    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(9, 9, 9, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}

/* Copiados de estilo backend marcos */

.nav-link-icon { text-align: center; }

.nav-link-icon > i,
.nav-link-icon .nav-link > i,
.nav-link-icon svg
{ color: var(--color-principal); }

.nav-link .texto {
    color: var(--color-secundario-opuesto);
    overflow: hidden;
    text-overflow: ellipsis;
}

/* .nav-link-icon > div { font-size: .8em; } */
div.nav-link {
    padding: 0;
}

.portada .dropdown-menu {
    background-color: var(--color-principal);
    color: var(--color-principal-opuesto);
    border-radius: 5px;
}

.portada .dropdown-menu a{
    color: var(--color-secundario);
    border-top: 1px dotted var(--color-secundario);
}

.portada .dropdown .dropdown-divider {
    border-top: 1px solid var(--color-secundario);
}

.home__list-language {
  border-top: 1px dotted var(--color-principal);
}

.home__notificaciones {
  color: var(--color-principal);
}

.disabled .home__notificaciones {
  color: var(--color-gris-oscuro);
}

.home__badge-notificacion {
  top: .5rem;
  right: 2.7em;
}

@media (min-width: 768px) {
  .nav-link .texto {
    font-size: 0.7em;
  }
  .nav-item {
    width: 62px;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-left: 2px;
    padding-right: 2px;
  }
}

@media (max-width: 767px) {
    .nav-link .fa, .nav-link .fas { display: none; }
    .nav-link .texto { width: unset;}
    .navbar li .nav-link, .navbar li .login__pill-item > a { border-bottom: 1px dashed #fff; }
    .navbar-nav .nav-link.dropdown { border-bottom: 1px dashed #FFFFFF; }
    .navbar-nav form .nav-link.dropdown-toggle{ border-bottom: none;}
    .navbar li:first-child .nav-link, .navbar li:first-child .login__pill-item > a { padding-top: 1em; }
    .navbar li:last-child .nav-link, .navbar li:last-child .login__pill-item > a { border-bottom: none; }
    .home__badge-notificacion { top: 0.7rem; right: unset;}
    .dropdown-menu { border-radius: 0; text-align: center;}
    .dropdown-menu a {padding-right: 1.5rem;}
    .home__badge-notificacion-mobile { right: 1.5em;}
    .dropdown-toggle::after { display: none; }
}

.notificacion {
  background-color: var(--color-principal);
  color: var(--color-secundario);
  text-transform: initial;
}

.notificacion--leida {
  background-color: #FFFFFF;
}

.notificacion a {
  color: var(--color-secundario)
}

.mapa-portada__wrapper {
  width: 100%;
  height: 500px;
  overflow: hidden;
  display: flex;
}

.mapa-portada__container{
  width: 100%;
  height: 100%;
  transition: width 200ms linear;
}

.mapa-portada__container--collapsed {
  width: calc(100% - 300px);
  transition: width 200ms linear;
}

.mapa-portada__recorrido-detalle {
  position: relative;
  top: 0;
  height: 100%;
  width: 0px;
  right: -200px;
  background-color: var(--color-principal);
  color: var(--color-secundario);
  transition: right 200ms linear, width 200ms linear;
}

.mapa-portada__recorrido-detalle--abierto {
  width: 300px;
  right: 0;
  padding: 1rem;
  transition: right 200ms linear, width 200ms linear;
}

.titulo-proyecto {
  text-align: center;
}

.icono-mapa-deporte{
  width: 30px !important;
  height: 30px !important;
  margin-left: -15px !important;
  margin-top: -15px !important;
  background-color: var(--color_fondo_secundario);
  border-radius: 50%;
  padding: 5px;
}

.icono-mapa-deporte.highlated{
  width: 50px !important;
  height: 50px !important;
  margin-left: -20px !important;
  margin-top: -20px !important;
  background-color: var(--color-secundario);
  border-radius: 50%;
  padding: 5px;
}

.icono-mapa-deporte > svg {
  width: 100% !important;
  height: 100%;
  stroke-width: 0;
}

.icono-mapa-deporte > svg path {
  fill: var(--color_fondo);
  stroke: var(--color_fondo);
  stroke-width: 0;
}

.icono-mapa-deporte.highlated > svg path {
  fill: var(--color-principal);
  stroke: var(--color-principal);
}

.leaflet-container a.leaflet-control-zoom-in {
  color: var(--color_fondo);
}

.leaflet-container a.leaflet-control-zoom-out {
  color: var(--color_fondo);
}

.mapa-portada__recorrido-cabecera {
  background-color: rgba(255, 255 ,255 , 0.7);
  padding: .5rem 1rem;
}

.mapa_portada__cerrar-detalle {
  background-color: transparent;
  border: none;
  position: absolute;
  right: 0;
  top: 0;
  margin: 1rem;
}

.mapa_portada__cerrar-detalle:focus,
.mapa_portada__cerrar-detalle:active {
  outline: 0;
}

.mapa-portada__recorrido-deporte {
  width: 39px;
  max-height: 39px;
  background-color: var(--color-secundario);
  color: var(--color-principal);
  padding: 3px;
}

.mapa-portada__recorrido-datos {
  text-transform: initial;
}

.mapa-portada__recorrido-datos-arriba {
  font-size: 0.8em;
}

.mapa-portada__recorrido-datos-abajo {
  font-size: 0.8em;
  background-color: var(--color-secundario);
  color: var(--color-principal);
}

.mapa-portada__logo-proyecto {
  width: 80px;
  height: 80px;
  margin-bottom: 1rem !important;
  background-color: #fff;
  border: 5px solid #fff;
}

.mapa-portada__logo-proyecto > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.mapa-portada__recorrido-descripcion {
  font-size: 0.8em;
}

.footer {
  background-color: var(--color-secundario);
  color: var(--color_texto);
}

.footer a {
  color: var(--color_texto);
  transition: color 200ms linear;
}

.footer a:hover {
  text-decoration: none;
  color: var(--color_texto_hover);
  transition: color 200ms linear;
}

.footer__list {
  list-style: none;
  padding: 0;
}

.footer__list-item {
  font-size: .8rem;
}

.footer .fa-inverse {
  color: var(--color-secundario);
  transition: color 200ms linear;
}

.footer .fa-stack:hover .fa-stack-2x {
  color: var(--color_texto);
  transition: color 200ms linear;
}

.footer .fa-stack:hover .fa-facebook {
  color: #1877f2;
  transition: color 200ms linear;
}

.footer .fa-stack:hover .fa-twitter {
  color: #1da1f2;
  transition: color 200ms linear;
}

.footer .fa-stack:hover .fa-instagram {
  color: #db2977;
  transition: color 200ms linear;
}

.footer .fa-stack:hover .fa-youtube {
  color: #ff0000;
  transition: color 200ms linear;
}

.footer button {
  border: none;
  color: white;
  font-size: 0.8em;
  padding: 0;
}

.footer form {
  display: flex;
  flex-direction: column;
}

@media (max-width: 767px) {
  .footer {
    text-align: center;
  }
}

.home__recorrido {
  background-color: var(--color-principal);
  color: var(--color-secundario);
  transition: background-color 400ms ease;
}

.home__recorrido:hover {
  background-color: var(--color-terciario);
}

.home__recorrido--cargando {
  height: 300px;
}


.home__list-language {
  background-color: transparent;
  border-radius: 0;
  color: var(--color-secundario);
}
.home__img-lang {
  border: 1px solid var(--color-secundario);
  height: 20px;
}
.home__icono-idiomas {
  /*color: var(--color-principal);*/
}

.videoportada iframe{
  max-width: 100%;
}

.videoportada_titulo {
  line-height: 1.1em;
  margin-bottom: 0.8em;
}

.videoportada_image {
  position:relative;
  overflow:hidden;
  padding-bottom:100%;
}

.videoportada_image img{
  position: absolute;
  left: 50%;
  max-height: 150px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.videoportada_lista {
  max-height: 607px;
  overflow: auto;
}

.videoportada-activo {
  background-color: #ffffff;
}

@media (max-width: 1199.98px) {
  .videoportada iframe {
    width: 290px !important;
    height: 517px !important;
  }
  .videoportada_lista {
    max-height: 517px;
  }
}

@media (max-width: 575.98px) {
  .videoportada iframe{
    width: 90vw !important;
    height: 142vw !important;
  }
  .videoportada_lista {
    max-height: unset;
  }
  .videoportada p {
    line-height: 1.2em;
    font-size: 0.9em;
  }
}

.ais-Hits {
  font-family: var(--font-family);
}

.ais-Hits-list {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 0;
    row-gap: 0;
    padding: 0;
    margin: 1em 0 0;
}

.ais-Hits-item {
    display: block;
    border: none;
    box-shadow: none;
    margin: 0;
    padding: 1.4em 1.4em;
    width: 100%;
}
.ais-Hits-item:nth-child(even) {
    background: #ffefc9;
}
.ais-Hits-item a,
.ais-Hits-item a:hover,
.ais-Hits-item a:focus,
.ais-Hits-item a:active {
    color: var(--color_fondo);
    text-decoration: none;
}

.ais-Hits a .hit-image {
    max-width: 25%;
}

.ais-Hits a .hit-data {
    max-width: 75%;
    padding-left: 15px;
}

.ais-Hits a img.proyecto-captura {
    object-fit: cover;
    width: 135px;
    height: 135px;
    margin: 0 auto;
    font-size: 10px;
    background-color: #fff;
}

.ais-Hits a img.proyecto-logo {
  width: 55px;
  height: 55px;
  position: absolute;
  margin: 10px;
}

.ais-Hits {
    font-weight: 300;
    text-transform: uppercase;
}

.ais-Hits .hit-name {
    font-size: 1.5em;
    text-transform: uppercase;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1em;
    margin-top: -5px;
}

.ais-Hits .hit-description {
    line-height: 1.1em;
    text-transform: none;
    width: 100%;
    display: table;
}

.ais-Hits .hit-description-text {
    overflow: hidden;
    line-height: 2.2ch;
    text-transform: initial;
}

.ais-Hits .badge-container {
    display: inline;
    margin-right: 3px;
}

.ais-Hits .hit-date p{
    margin-bottom: 0.3rem;
}

.ais-Hits .hit-date strong{
    font-weight: bold;
}

.ais-Hits .badge {
    font-weight: 300;
    text-transform: uppercase;
    margin-top: 7px;
    font-size: 0.9em;
}

.ais-CurrentRefinements-item{
    background-color: var(--color-secundario);
}

.ais-RefinementList-label {
    margin-bottom: 0.3em;
}

.ais-RefinementList-labelText {
    padding: 0 0.4em;
}

.ais-ClearRefinements-button {
    width: 100%;
    background-color: var(--color-gris-oscuro);
    font-size: 1.2em;
    padding: 0.5em 0.1em
}

.ais-ClearRefinements-button:hover{
    background-color: var(--color-secundario);
}

.ais-CurrentRefinements-label {
    font-weight: bold;
}

.ais-CurrentRefinements-label::after {
    content: ":";
}

.ais-CurrentRefinements-category {
    margin-left: 0.5em;
}

.ais-RefinementList-count {
    background-color: var(--color-secundario);
    color: var(--color-terciario);
}

.filtros .close-window {
    font-size: 26px;
}

.herramientas-busqueda .btn{
    font-size: 1.4em;
}

.hit-info {
  padding: .4rem 0;
  display: flex;
  align-items: center;
}

.hit-deporte {
  width: 30px;
  background-color: var(--color-secundario);
  stroke: var(--color-principal);
  fill: var(--color-principal);
  stroke-width: 0;
}

.hit-deporte > svg path {
  fill: var(--color-principal);
  stroke-width: 0;
}

.hit__btn-mas {
  background-color: transparent;
  border: 0;
  font-weight: bold;
}

.hit__btn-mas:focus,
.hit__btn-mas:active{
  border: 0;
  outline: none;
}

.hit__check {
  height: 25px;
  vertical-align: baseline;
}

@media (max-width: 991px) {
    .ais-Hits a img.proyecto-captura {
        width: 125px;
        height: 125px;
    }
}

@media (max-width: 767px) {
    .ais-Hits a img.proyecto-captura {
        width: 100px;
        height: 100px;
    }
}
@media (max-width: 575px) {

    .ais-Hits a .hit-image {
      max-width: 30vw;
    }

    .ais-Hits a img.proyecto-logo {
      width: 9vw;
      height: 9vw;
      margin: 1.5vw;
    }

    .ais-Hits a .hit-data {
        font-size: 0.8em;
        max-width: 70vw;
    }

    .ais-Hits a img.proyecto-captura {
        width: 25vw;
        height: 25vw;
    }

    .ais-Hits-item{
        padding: 10px;
    }
}



/* Poño esto aquí para asegurarme de que se sobrescriben os cambios cos css externos ...*/



/* esto copiado do traballo de marcos na administración de proyectos */

@media (max-width: 767px) { #idiomas .idioma .texto { display: none; } }

.boton-filtros { padding: 0px; }

.boton-filtros > i { font-size: 1.2em; padding-top: 4px; }

.boton-filtros > .texto-boton { font-size: 0.6em; line-height: 1.1em; }

.herramientas-busqueda .col-form-label { padding-top: 0px; padding-bottom: 0px; }

.herramientas-busqueda .form-group { background-color: #1A1A1A; color: #fff; border-radius: 4px; padding: 2px 14px 9px; text-transform: uppercase; }

.herramientas-busqueda .form-control { height: 1.7em; padding: 0 0.2em; }



.filtros {
    position: fixed;
    top: 15vh;
    background-color: #fc0;
    color: #1a1a1a;
    right: -900px;
    z-index: 1030;
    border-left: 1px solid #1a1a1a;
    border-top: 1px solid #1a1a1a;
    border-bottom: 1px solid #1a1a1a;
    border-radius: 11px 0 0 10px;
    padding: 1em;
    transition: right .4s ease;
    box-shadow: -5px 7px 6px -1px rgba(0,0,0,.33);
    font-family: var(--font-family);
}
.filtros.mostrar {
    right: 0px;
}

.filtros .scroll-zone {
    max-height: 70vh;
    overflow: auto;
    padding: 0.6em;
}

.filtros .form-inline .form-control { border-radius: 5px; }

.filtros h4 { border-bottom: 1px dashed #000; text-transform: uppercase; font-weight: bold; }

.filtros h5 { font-weight: bold; padding-top: 0.5em; }

.filtros .close-window { display: block; float: right; cursor: pointer; }

.filtros label.col-form-label { font-weight: bold; }

.filtros .DayPickerInput {
  max-width: 34vw;
}

.filtros .form-group {
  width: 100%;
}

.filtro-distancia .css-i35pig-Slider .slider-tooltip-visible {
  padding: 0 5px;
}

.filtro-distancia .css-i35pig-Slider {
  margin-right: 5px;
}

.tooltip-filtro {
  font-size: 0.8em;
}
/*
.form-row .ais-RefinementList {
    display: block;
}
*/

.ais-SortBy {

}
.ais-SortBy-select {
    width: 100%;
}

@media (max-width: 575px) {

  .visor-publico--buscar .filtros {
    font-size: 3.4vw;
  }
  .visor-publico--buscar .filtros .DayPickerInput input {
    height: 26px;
    padding: 5px;
    font-size: 3.2vw;
  }
  .visor-publico--buscar .filtros label {
    margin: 1vw 0;
  }
  .visor-publico--buscar .filtros li {
    min-height: unset;
  }

  .visor-publico--buscar .filtros .form-group {
    margin-bottom: 0;
  }
}

.herramientas-busqueda,
.buscador {
  font-family: var(--font-family);
}

.herramientas-busqueda .css-dmx3ll-InputIcon-left {
  top: 7px;
}

.visor-publico--buscar {
  font-family: var(--font-family);
}

.visor-publico--buscar .css-iioxla-Button {
  background-color: var(--color-terciario);
  font-family: var(--font-family);
}

.visor-publico--buscar .css-iioxla-Button:hover{
  text-decoration: none;
}

@media (max-width: 575px) {
  .herramientas-busqueda {
    font-size: 3.6vw;
    margin-top: 1em;
    border-radius: 6px;
    padding: 5px;
  }

  .herramientas-busqueda .btn {
    border: none;
    padding: 0;
  }

  .herramientas-busqueda .form-group {
    padding: 5px;
    margin-bottom: 0;
  }

  .herramientas-busqueda #div_id_ordenar label {
    display: none;
  }
}



.proyecto__avatar {
  width: 130px;
  height: 130px;
  background-color: #fff;
  border-radius: 50%;
  border: 10px solid #fff;
}

.proyecto__avatar > img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  object-fit: cover;
}

.proyecto__link-canal {
  font-size: 1.1rem;
}

.proyecto__btn-compartir {
  bottom: 1rem;
  right: 1rem;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: var(--color-secundario);
  color: var(--color-principal);
  border: 3px solid var(--color-principal);
  outline: none;
}

.proyecto__btn-compartir:focus {
  outline: none;
}

.proyecto__lista {
  padding-bottom: 5rem;
}

.proyecto__contenido {
  position: absolute;
  height: calc(100% - 60px);
  margin-top: 60px;
  width: 100%;
}

@media (max-width: 768px) {
  .proyecto__avatar {
    width: 80px;
    height: 80px;
  }
}

.top-bar {
    background: var(--color_fondo);
    color: var(--color_fondo_secundario);
    height: var(--top-bar-height);
    display: grid;
    grid-template-columns: calc(2 * var(--top-bar-height)) auto calc(2 * var(--top-bar-height));
    font-size: 22px;
    text-align: center;
    line-height: var(--top-bar-height);
    font-weight: bold;
}
.top-bar__left {
    grid-row: 1;
    grid-column: 1;
    text-align: left;
    padding-left: 20px;
}

.top-bar__pantalla-completa {
    margin-top: -5px;
    margin-right: 4px;
    font-size: 13px;
}

.top-bar__compartir {
  font-size: 11px;
  margin-top: -5px;
  margin-right: 4px;
}

.top-bar__center {
    color: var(--color_fondo_secundario);
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    grid-row: 1;
    grid-column: 2;
}

.top-bar__center-rows {
    display: grid;
    grid-template-rows: 35% 30% 35%; /* proyecto, recorrido, esquema */
}
.top-bar__titulo {
    line-height: calc(var(--top-bar-height) / 3);
    font-size: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.top-bar__subtitulo {
    color: white;
    line-height: calc(var(--top-bar-height) / 3);
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.top-bar__esquema {
    color: white;
    line-height: calc(var(--top-bar-height) / 3);
    font-size: 12px;
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
}
.top-bar__esquema li {
    display: inline;
    margin-left: 1em;
}
.top-bar__barrita {
  width: 2rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  height: .8rem;
  border-radius: .3em;
  font-size: .9em;
}
.top-bar__barrita--sector {
  width: auto;
  height: auto;
  color: black;
  line-height: 1;
  padding: 1px 3px;
  font-weight: normal;
}

@media (max-width: 480px) {
    .top-bar__esquema li {
        font-weight: 200;
        margin-left: 0.1vw;
        font-size: 2.8vw;
    }
    .top-bar__barrita {
        width: 4vw;
        height: 1.8vw;
        margin-right: 1vw;
        border-radius: 3px;
    }
    .top-bar__barrita--sector {
        width: auto;
        height: auto;
        line-height: 1;
        padding: 1px 3px;
    }

}

.top-bar__right {
    grid-row: 1;
    grid-column: 3;
    font-size: 1.4em;
    text-align: right;
    padding-right: .5em;
}
.top-bar__right > a {
    color: var(--color_fondo_secundario);
}
.top-bar__right > a:hover,
.top-bar__right > a:active,
.top-bar__right > a:focus {
    color: var(--color_fondo_secundario);
    text-decoration: none;
}

.top-bar__desplegar {
  width: 100%;
  height: 10px;
  color: white;
  position: absolute;
  margin-top: var(--top-bar-height);
  z-index: 9999;
  background-color: var(--color-secundario);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .8em;
}

.top-bar__icono-mapa {
  width: 35px;
}

.top-bar__check-recorrido {
  height: 17px;
  margin-top: 2px;
  vertical-align: baseline;
}


.compartir__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--color-secundario);
}

.compartir__ul {
    list-style: none;
    padding: 15px;
    text-align: center;
}

.compartir__item {
    display: inline-block;
    margin: 20px;
}

.compartir__close {
    border: none;
    border-radius: 6px;
    background: none;
    color: var(--color-principal);
    font-size: 50px;
    line-height: 30px;
    font-weight: bold;
    margin: 20px;
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 2em;
}

.compartir__title {
    color: var(--color-principal);
    font-size: 30px;
    margin: 20px;
    text-align: center;
}

.react-share__btn-label {
    font-size: .8rem;
    color: white;
    text-transform: uppercase;
    margin-top: .4rem
}


.compartir__link {
    width: 80%;
    max-width: 40rem;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    background: white;
    padding: .5rem 1rem;
}
.compartir__link-input {
    border: none;
    outline: none;
    flex-grow: 1;
    text-align: center;
}
.compartir__link-btn {
    background: none;
    border: none;
    font-weight: bold;
    background-color: var(--color-principal);
}

@-webkit-keyframes compartirOpacityOnAndOff {
    0% {
        opacity: 0;
    }
    20%{
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes compartirOpacityOnAndOff {
    0% {
        opacity: 0;
    }
    20%{
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.compartir__copiado {
    opacity: 0;
    text-align: center;
    color: white;
}

.compartir__copiado--blink {
    -webkit-animation: compartirOpacityOnAndOff 4s normal forwards;
            animation: compartirOpacityOnAndOff 4s normal forwards;
}


.compartir__iframe-wrapper {
    display: flex;
    flex-direction: row;
    width: 80%;
    max-width: 80rem;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
}
.compartir__iframe {
    width: 75%;
    padding: .5rem;
}
.compartir__iframe > iframe {
    width: 100%;
    border: 2px solid white;
    /*height: 0;
    padding-top: 40%;*/
}
.compartir__iframe-code {
    width: 25%;
    padding: .5rem;
}
.compartir__code-title {
    color: white;
    margin-bottom: .5rem;
}
.compartir__iframe-code > textarea {
    width: 100%;
    min-height: 10rem;
}


/* QR */
.compartir__qr {
  position: relative;
  float: left;
  margin-left: auto;
  margin-right: auto;
}
.compartir__qr  svg {
  /* Parece k non podemos cambiar o fill e o stroke */
  max-width: 80%;
}
.compartir__qr  canvas {
  width: 300px !important;
  height: auto !important;
  background: white;
  padding: 20px;
  max-width: 80%;
}
.compartir__qr-hover {
  position: absolute;
  background: rgba(0, 0, 0, .5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: var(--color-principal);
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  cursor: pointer;
}
.compartir__qr-hover:hover {
  opacity: 1;
}
.compartir__qr-label {
  font-size: 1.2rem;
  font-weight: bold;
}

@media (max-width: 767px) {
    .compartir__iframe-wrapper {
        flex-direction: column;
    }
    .compartir__iframe,
    .compartir__iframe-code {
      width: 100%;
    }

    .compartir__qr  svg {
      max-width: 50%;
    }
}

.recorrido-list {
    margin: 0;
    list-style: none;
    padding: 0;
    /*height: 100%;*/
    overflow: auto;
    display: block;
    margin: 0 auto;
}

.recorrido-list a:hover {
  text-decoration: none;
  color: var(--color-gris-oscuro);
}

.recorrido-list > li {
    padding: 25px 0;
    display: block;
}

.recorrido-list li a{
  max-width: 500px;
  display: block;
  margin: 0 auto;
}

.recorrido-list > li:nth-child(even) {
  background-color: #ffefc9;
}

.recorrido-list__label {
    text-transform: uppercase;
    display: block;
    text-align: center;
    color: white;
    margin: 0;
    font-size: 1.3em;
    font-weight: bold;
    padding: 0;
    line-height: 1.1em;
}
.recorrido-list__label,
.recorrido-list__label:hover,
.recorrido-list__label:active,
.recorrido-list__label:focus {
    color: white;
    text-decoration: none;
}

.recorrido-list__sectores {
    list-style: none;
    padding: 0;
}

.recorrido-list__sectores > li {
    text-align: center;
}

.recorrido-list__miniatura {
  float: left;
  position: absolute;
  display: block;
}

.recorrido-list__recorrido-nombre {
  background-color: rgba(255, 255 ,255 , 0.7);
  padding: 2px 7px;
  font-weight: bold;
  font-size: 1.2em;
}

.recorrido-list__trayecto {
  width: 35px;
  float: left;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  margin: 5px;
  font-weight: bold;
}

.recorrido-list__captura {
  max-width: 100%;
  height: 225px;
  background-size: cover;
  background-position: center;
  background-color: #c1d1c1;
}

.recorrido-list__deporte_icono {
  width: 55px;
  max-height: 55px;
  background-color: var(--color-secundario);
  color: var(--color-principal);
  padding: 1px;
  float: left;
  margin-right: 5px;
}

.recorrido-list__deporte_icono_default {
  font-size: 2em;
  width: 55px;
  height: 55px;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
}

.recorrido-list__datos {
  text-transform: none;
  margin-top: 7px;
}

.recorrido-list__datos_abajo,
.recorrido-list__datos_arriba {
  display: flex;
  justify-content: space-around;
}

.recorrido-list__datos_abajo {
  color: var(--color-principal);
  background-color: var(--color-secundario);
  margin-top: 7px;
}

.recorrido-list__deporte_icono a{
  color: var(--color-principal);
}

.recorrido-list__esquema {
  display: flex;
  justify-content: center;
  background-color: var(--color-secundario);
  text-transform: initial;
}
/*
.recorrido-list__esquema > li > span {
    width: 20px;
    display: inline-block;
    height: .8em;
    border-radius: .4em;
    margin-right: .5em;
}
*/
.recorrido-list__sectores {
    list-style: none;
    padding: 0;
}

.recorrido-list__sectores-li {
    font-size: 16px;
    line-height: 1em;
    margin: 6px .5em;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.recorrido-list__icono {
    display: inline-block;
    color: #22D923;
    width: 22px;
    height: 22px;
    margin-right: 0.5em;
    border-radius: 4px;
    overflow: hidden;
    padding: 2px;
}
.recorrido-list__icono > svg {
    height: 17px;
    width: 17px;
}

.recorrido-list__sector-nombre {
    background-color: #22D923;
    color: #111111;
    font-size: 14px;
    display: inline-block;
    padding: 3px 4px;
    border-radius: .4em;
    margin-right: .5em;
}

.recorrido-list__sectores-nombre {
    color: #111111;
}

.recorrido-list__sectores-distancia {
    color: #FFFFFF;
}

.recorrido-list-dificultad {
  width: 0.8em;
  height: 0.8em;
  display: inline-block;
  border-radius: 0.5em;
}

.recorrido-list-dificultad.muydificil {
  color: #980239 !important;
}

.recorrido-list-dificultad.dificil {
  color: #ff0000 !important;
}

.recorrido-list-dificultad.moderado {
  color: #f88800 !important;
}

.recorrido-list-dificultad.facil {
  color: #84b711 !important;
}

.recorrido-list-dificultad.muyfacil {
  color: #1d715d !important;
}

.recorrido-list__check {
  width: 12px;
  vertical-align: baseline;
}

@media (max-width: 500px) {
  .recorrido-list__datos {
    text-transform: none;
    margin-top: 7px;
    margin-left: 0.8em;
    margin-right: 0.8em;
  }
}

@media (max-width: 400px) {
  .recorrido-list__datos {
    font-size: 4vw;
  }
}

@media (max-width: 359px) {
  .recorrido-list__deporte_icono {
    width: 14vw;
    max-height: 14vw;
  }
  .recorrido-list__deporte_icono_default {
    font-size: 2em;
    width: 14vw;
    height: 14vw;
  }
}

.top-slider {
    background: var(--color_fondo_secundario);
    color: var(--color_fondo);
    height: var(--top-bar-height);
    display: grid;
    grid-template-columns: var(--top-bar-height) auto var(--top-bar-height);
    font-size: 22px;
    line-height: var(--top-bar-height);
    text-align: center;
    font-weight: bold;
}
.top-slider__left {
    grid-row: 1;
    grid-column: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.top-slider__button {
  background: none;
  border: none;
}

.top-slider__button:hover,
.top-slider__button:active,
.top-slider__button:focus {
  outline: none;
}

.top-slider__center {
    color: var(--color_fondo);
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    grid-row: 1;
    grid-column: 2;
    display: grid;
    grid-template-rows: calc(var(--top-bar-height) / 2) calc(var(--top-bar-height) / 2);
}
.top-slider__mapa {
    line-height: calc(var(--top-bar-height) / 2);
    font-size: 16px;
}
.top-slider__dots {
    list-style: none;
    margin: 0;
    padding: 0;
}
.top-slider__dots > li {
    display: inline-block;
    margin-left: .25em;
    margin-right: .25em;
    height: calc(var(--top-bar-height) / 2);
}

.top-slider__dot {
    height: 10px;
    width: 10px;
    padding: 0;
    border-radius: 100%;
    border: none;
    text-shadow: none;
    display: block;
    float: left;
    background: var(--color_fondo);
    opacity: .5;
}

.top-slider__dot--current {
    height: 18px;
    width: 18px;
    margin-top: -5px;
    opacity: 1;
}

.top-slider__right {
    grid-row: 1;
    grid-column: 3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.top-slider .fa-chevron-left,
.top-slider .fa-chevron-right{
    font-size: 1.8em;
}

.personal-item__resumen {
  background-color: var(--color-secundario);
  color: var(--color-principal);
}



.perfil__wrapper {
    background: var(--color-secundario);
    color: var(--color-terciario);
    width: 100%;
    min-width: 300px;
    height: 100vh;
    overflow: hidden auto;
    margin: 0 auto;
}

.perfil__grafico-sizer {
    background: var(--color-secundario);
    border-radius: 6px;
    padding-top: 86%; /* relación de aspecto */
    position: relative;
}
.perfil__grafico {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 0 10px;
}

.perfil__grafico .VictoryContainer {
    text-transform: none;
}

.perfil__grafico .btn-group {
  float: right;
}

.perfil__grafico .btn {
  color: var(--color-principal);
}

.perfil__grafico .btn.disabled {
  color: var(--color-gris-oscuro);
}

.perfil__grafico .btn.focus, .perfil__grafico .btn:focus {
  box-shadow: none;
}

.perfil__topbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.perfil__topbar h3{
  margin-bottom: 2px;
  margin-top: 6px;
  font-size: 0.9rem;
  text-align: left;
  color: var(--color-principal);
  max-width: calc(100% - 85px);
  min-width: 50%;
}

.perfil__topbar h3 span{
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.perfil__proyectonombre {
  max-width: 49%;
  text-align: right;
}

.perfil__recorridonombre {
  max-width: 49%;
  text-align: left;
}

.perfil__tabla {
    color: var(--color-principal);
    padding: 0.2em 4px;
    text-align: center;
    font-size: .8em;
}

.perfil__tabla h5{
  margin-bottom: 0;
}

.perfil__selectorsectores {
  background-color: var(--color-principal);
  color: var(--color-secundario);
  margin-bottom: 3px;
}

.perfil__selectorsectores .btn {
  color: var(--color-secundario);
  padding: 0;
}

.perfil__datos h6 {
  font-weight: 600;
}

.perfil__topbar .btn {
  font-size: 1.3em;
  padding-left: 20px;;
}

img.perfil_logo {
  max-height: 28px;
  display: block;
  float: left;
  margin-right: 10px;
}

.perfil_mosca {
  bottom: 41px;
  position: absolute;
  max-width: 18vw;
  right: 31px;
  opacity: 0.3;
  pointer-events: none;
}

@media (orientation: landscape) {

    .perfil__grafico-sizer {
        /* restamos un em padding... */
        height: calc(100vh - var(--top-bar-height) - var(--footer-height) - 1em);
        padding-top: 0;
    }
}


.victory__chart-title {
    font-weight: bold;
    font-family: var(--font-family);
}
.victory__chart-subtitle {
    text-transform: capitalize;
    font-family: var(--font-family);
}

.mdi,
.mdi-set {
    display: inline-block;
    font: normal normal normal 24px/1 "Material Design Icons";
    font-size: inherit;
    text-rendering: auto;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.visor__main--perfil-captura .perfil__wrapper {
    padding: 0;
}
.visor__main--perfil-captura .perfil__grafico-sizer {
    height: 100vh;
    border-radius: 0;
}


.modal-km__wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: grid;
    background: rgba(255,255,255, .6);
    z-index: 5000;
    font-size: 0.8em;
}

.modal-km__modal {
    background: var(--color-secundario);
    justify-self: center;
    align-self: center;
    border-radius: 6px;
    z-index: 5000;
    max-width: 80%;
    max-height: 90%;
}


.mapa-modal {
    /*
    flex: 1;
    align-self: center;
    */
    background: var(--color-secundario);
    border-radius: 10px;
    padding: 1em;
    color: var(--color-terciario);
    width: 100%;
    min-width: 300px;
    max-width: 800px;
    /* margin: 0 auto; */
}
.scroll-vertical > .mapa-modal, .scroll-vertical--osrcuro .mapa-modal {
    height: auto;
}
.mapa-submodal {
    /*overflow: hidden;*/
    font-size: 1.2em;
    background-color: transparent;
}

.mapa-submodal__head {
    background: var(--color-principal);
    border-radius: 6px;
    line-height: unset;
    font-size: 1.3em;
}
.mapa-submodal__titulo {
    color: var(--color-secundario);
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: unset;
    text-align: left;
    padding-left: 0.6em;
}
.mapa-modal__body{
  overflow-y: auto;
}

.overflow-hidden .mapa-submodal__body {
  overflow: hidden;
}

.mapa-submodal__close {

}
.mapa-submodal__close button {
    border: none;
    background: transparent;
    color: var(--color-secundario);
    font-weight: bold;
    font-size: 1em;
}

.mapa-submodal__lista {
    text-align: left;
}

.mapa-submodal__lista--pdis {
    list-style: none;
    text-align: center;
    padding: 0;
    margin: 0;
}

.mapa-submodal__lista--pdis {
  overflow-y: auto;
  height: calc(100% - 55px);
  padding-right: 1rem;
}

.mapa-submodal__lista--pdis > li {
    border-bottom: 1px dashed var(--color-terciario);
    padding: 5px;
}
/*

.mapa-submodal {
    max-height: calc(96vh - 330px);
    overflow-y: auto;
}

@media (min-width: 1024px) {
    .mapa-submodal {
        max-height: calc(96vh - 370px);
    }
}

@media (min-width: 1900px) {
    .mapa-submodal {
        max-height: calc(96vh - 410px);
    }
}
*/

.mapa-submodal__esquema {
    list-style: none;
}
.recorrido-list__esquema--vertical {

}

.recorrido-list__esquema--vertical li {
    display: block;
}



.mapa-modal-pdi {

}

.pdi-servicio__title {
    font-size: 1.2em;
    font-weight: bold;
}
.pdi-servicio__list {
    margin: .5em 0;
    padding: 0;
}
.pdi-servicio {
    list-style: none;
    text-align: left;
    margin-bottom: .5em;
    display: flex;
    flex-direction: row;
}
.pdi-servicio__icon {
    height: 2.5em;
    width: 2.5em;
    margin-right: .5em;
    border-radius: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.pdi-servicio__nombre {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-pdi__altitud {
  font-size: 1.3rem;
}

.modal-pdi__servicios {
  display: flex;
}

.modal-pdi__descripcion {
  text-transform: none;
}

.modal-pdi__imagen > img {
  max-width: 100%;
  max-height: 70vh;
}

.modal-pdi__avatar-user {
  width: 3em;
  max-height: 3em;
  object-fit: cover;
  border: 3px solid #FFFFFF;
  background-color: #FFFFFF;
}

.grafico__area {
  stroke-linejoin: round;
  fill: #FFCC00;
  stroke-width: 1px;
}

.grafico__eje line {
  stroke: #4F4F4F;
}
.grafico__eje path {
  stroke: var(--color-terciario);
  stroke-width: 2px;
}
.grafico__eje text {
  fill: var(--color-terciario);
}
.grafico__eje .tick line {
  stroke: var(--color-terciario);
}
.grafico__eje .tick:first-of-type line {
  stroke: #D0D0D0;
}
.grafico__eje .tick:last-of-type line {
  stroke: #D0D0D0;
}

.grafico__ejex > path,
.grafico__ejey > path,
.grafico__ejex > .tick > line,
.grafico__ejey > .tick > line{
  stroke: var(--color-terciario);
}

.grafico__ejey > .tick > text,
.grafico__ejex > .tick > text{
  fill: var(--color-terciario);
  text-transform: none;
  stroke: var(--color-terciario);
  stroke-width: .5;
}

.grafico__container {
  position: relative;
}

.pdi__linea {
  stroke-width: 1;
  fill: var(--color-terciario);
  stroke: var(--color-terciario);
}
.pdi__circulo {
  stroke-width: 0;
  fill: black;
}
.pdi__titulo {
  fill: #000000;
  font-size: 12px;
  font-family: Arial;
  text-transform: uppercase;
}
.pdi__altura {
  fill: black;
  font-size: 16px;
  font-weight: bold;
  font-family: Arial;

}
.pdi__recuadro {
  stroke-width: 1;
  /*stroke: red;*/
  fill: transparent;
}

.pdi__icono {
  width: 30px;
  height: 30px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 55px;
  transition: width .1s, height .1s, margin-left .1s, border-radius .1s;
}

.pdi__icono--seleccionado {
  width: 45px;
  height: 45px;
  border-radius: .5em;
  margin-left: 47.5px;
  transition: width .1s, height .1s, margin-left .1s, border-radius .1s;
}

.pdi__icono i {
  font-size: 1rem;
  transition: font-size .1s;
}

.pdi__icono--seleccionado i {
  font-size: 1.5rem;
  transition: font-size .1s;
}

.pdi__icono--pk i {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.pdi__foreign, .pdi__foreign > div {
  pointer-events: none;
}

.pdi__foreign > div > div {
  pointer-events: all;
  cursor: pointer;
}

.slider-pdis__slide {
  min-width: 150px;
  width: calc(100% - 5px);
  height: 140px;
  background-color: var(--color_fondo_seleccionado);
  margin-bottom: 1em;
  overflow: hidden;
}

.slider-pdis__slidev--seleccionado {
  background-color: var(--color-principal);
  color: var(--color-secundario)
}

.slider-pdis__list-serv {
  display: flex;
  width: 100%;
  justify-content: center;
}

.slider-pdis__servicio {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-pdis__servicio--mas{
  background-color: var(--color-secundario);
  color: var(--color-principal);
  cursor: pointer;
}

.slider-pdis__distancias {
  font-size: .8rem;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: none;
  white-space: nowrap;
  max-width: 100%;
}

.slider-pdis__descripcion {
  font-size: .75rem;
  line-height: 1.2em;
  text-overflow: ellipsis;
  text-transform: none;
}

.slider-pdis__btn-mas {
  font-size: 0.8em;
}

.slider-pdis__slidev--seleccionado .slider-pdis__btn-mas {
  background-color: var(--color_fondo_seleccionado);
  color: #fff;
}

.icono-punto-kilometrico.con-servicios i.icon--text{
  font-size: 15px;
  border: 1px solid #fc0;
  border-radius: 13px;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icono-punto-kilometrico.con-servicios.kmdiv5 i.icon--text{
  border: 1px solid #111111;
}

.anim__icono-deporte > .marcador__recuadro svg{
  width: 30px;
}



.esquema {
    width: 100%;
    max-width: 100%;
    margin-bottom: 2em;
}

.esquema__body {
    padding: 20px 0 10px;
    display: block;
}

.esquema__sector-titulo {
  padding: 3px;
  border-radius: 6px;
}

.esquema__color {
    text-align: left;
}
.esquema__color > span {
}
.esquema__badge {
  width: 3em;
    /*height: 0.6em;*/
  border-radius: 15%;
  display: inline-block;
  font-size: .75em;
  text-align: center;
  font-weight: bold;
}
.esquema__icono {
  display: flex;
  width: 20px;
  justify-content: center;
  align-items: center;
}
.esquema__icono svg {
    fill: #FFFFFF;
    max-height: 17px;
}
.esquema__rep {
    width: 2em;
}
.esquema__nombre {
    text-align: left;
    line-height: 1em;
    padding-top: 3px;
    padding-bottom: 3px;
}
.esquema__rep2 {
    text-align: right;
    width: 2em;
}
.esquema__dist {
    text-align: right;
    min-width: 21%;
}

.esquema__foot {
    border-top: 1px dashed white;
}
.esquema__final-text {
    text-align: left;
    font-weight: bold;
}
.esquema__final-dist {
    text-align: right;
    font-weight: bold;
}

.esquema__sector-info {
  line-height: 1em;
  font-size: 0.8em;
}

.ul-esquema {
    list-style: none;
}

.ul-esquema li {
    text-align: left;
}

.esquema__totales {
  text-transform: initial;
  white-space: pre-line;
}

.esquema__deporte {
  background-color: var(--color-principal);
}

.esquema__deporte svg{
  width: 40px;
  fill: var(--color-secundario);
  stroke: var(--color-secundario);
  stroke-width: 0;
}

.esquema__deporte svg path {
  fill: var(--color-secundario);
  stroke: var(--color-secundario);
  stroke-width: 0;
}

.esquema__totales--altitudes {
  background-color: var(--color-principal);
  color: var(--color-secundario);
}

.esquema__div {
  cursor: pointer;
}

.esquema__foot-simple {
  border-top: initial;
}


.mapa-submodal__lista--pdis button {
    background: transparent;
    border: none;
    color: var(--color-principal);
    display: block;
}

.mapa-submodal__lista--pdis .card {
  padding: 0;
  border: none;
}

.mapa-submodal__lista--pdis .card-header {
  background-color: var(--color-principal);
  color: var(--color-secundario);
  border: none;
  padding: .5rem 1.25rem;
}

.mapa-submodal__lista--pdis .card-header h6 {
  font-weight: 700;
}

.mapa-submodal__lista--pdis .list-group {
  background-color: var(--color-secundario);
}

.mapa-submodal__lista--pdis .list-group-item {
  background-color: var(--color-secundario);
  color: var(--color-terciario);
  border: none;
  border-bottom: 1px dashed var(--color-terciario);
  margin-bottom: 0;
  cursor: pointer;
  font-weight: 600;
  display: flex;
  line-height: 30px;
}



.modal-cambiar-vista {
    list-style: none;
    margin: 1.2em 0;
    padding: 0;
}

.modal-cambiar-vista__item {
    text-align: left;
    margin-bottom: 0px;
    padding-bottom: 2px;
    border-bottom: 1px dashed var(--color-terciario);
}

.modal-cambiar-vista__item.seleccionada .modal-cambiar-vista__btn{
    font-weight: 800;
    color: var(--color-terciario);
    cursor: default;
}

.modal-cambiar-vista__item.seleccionada .modal-cambiar-vista__btn::before,
.modal-cambiar-vista__item.seleccionada .modal-cambiar-vista__btn::after {
    content: "\f0a5";
    padding-left: 0.5em;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    -webkit-animation: muevemueve 0.8s ease infinite;
            animation: muevemueve 0.8s ease infinite;
    display: block;
    float: right;
}

.modal-cambiar-vista__item.seleccionada .modal-cambiar-vista__btn::before {
    content: "\f0a4";
    padding-right: 0.5em;
    float: left;
    -webkit-animation: muevemueveleft 0.8s ease infinite;
            animation: muevemueveleft 0.8s ease infinite;
}

.modal-cambiar-vista__btn {
    border: none;
    padding: 5px;;
    background: none;
    display: block;
    color: var(--color-principal)
}


.modal-cambiar-vista__item:hover  .modal-cambiar-vista__btn {
    font-weight: bold;
}

@-webkit-keyframes muevemueve {
    0%, 100% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
    }

    50% {
        -webkit-transform: translate(6px, 0);
                transform: translate(6px, 0);
    }
}

@keyframes muevemueve {
    0%, 100% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
    }

    50% {
        -webkit-transform: translate(6px, 0);
                transform: translate(6px, 0);
    }
}

@-webkit-keyframes muevemueveleft {
    0%, 100% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
    }

    50% {
        -webkit-transform: translate(-6px, 0);
                transform: translate(-6px, 0);
    }
}

@keyframes muevemueveleft {
    0%, 100% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
    }

    50% {
        -webkit-transform: translate(-6px, 0);
                transform: translate(-6px, 0);
    }
}

.perfiles-lista.mapa-modal{
    max-width: unset;
    border-radius: 0;
}

.perfiles-lista.mapa-modal .mapa-submodal {
    max-width: 800px;
    margin: 0 auto;
}

.table-perfil-resumen {
    width: 100%;
    max-width: 100%;
    margin-top: 2em;
    border-collapse: collapse;
}

.table-perfil-resumen td {
    border-bottom: 2px dashed var(--color-terciario);
    padding: .5em .2em;
    white-space: nowrap;
    text-align: right;
}
.table-perfil-resumen td a {
    color: white;
}

.table-perfil-resumen tr > td.perfil-link {
    cursor: pointer;
}

@media (max-width: 440px) {
    .table-perfil-resumen {
        font-size: 2.9vw;
    }
}

.control-expanded .mapa-modal__wrapper.perfil__wrapper .mapa-submodal__body > div {
    margin-top: 10px;
    border-radius: 6px;
}

.mapa-submodal__body {
    max-width: 92vw;
    margin-top: 0.5em;
}

.mapa-submodal__body .chartjs-size-monitor {
    margin-top: 0.5em;
}


@media only screen and (max-device-height: 540px) and (orientation : landscape) {
    .control-expanded .mapa-modal__wrapper.perfil__wrapper {
        min-height: 90vh;
        position: fixed;
        top: 0px;
        width: 100%;
        padding: 0;
        overflow-y: auto;
        z-index: 10;
        height: 100%;
    }

    .control-expanded .mapa-modal__wrapper.perfil__wrapper .mapa-submodal__body > div {
        margin-top: 5px;
    }
}

.mapa-submodal__lista--pdis,
.mapa-submodal__lista--pdis button {
  color: var(--color-terciario);
}

.mapa-submodal__lista--pdis button.btn-pdi-recorrido {
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 1.05em;
  padding: .75rem 1.25rem;
}

.mapa-submodal__lista--pdis button.btn-pdi-recorrido > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  line-height: 1em;
}

.distancia-pdi {
  float: right;
  display: inline-flex;
}

.lista-servicios-pdis {
  display: inline-flex;
  padding: 0 10px;
}

.icono-servicio {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px 1px;
  margin: 2px;
  height: 26px;
  width: 26px;
}

.icono-servicio > i {
  font-size: 16px;
}

.icono-mas-servicio {
  background-color: var(--color_fondo_secundario);
  color: var(--color_fondo)
}

.nombre-pdi {
  display: flex;
  line-height: 30px;
}

button.mapa-submodal__btn-visibilidad {
  color: var(--color-secundario);
}

.mapa-submodal__btn-visibilidad:focus,
.mapa-submodal__btn-visibilidad:active {
  outline: none;
}

.container-tabs-sectores-pdis{
  display: inline-flex;
  margin: 8px 0;
}

.container-tabs-sectores-pdis .nav-link {
  border-radius: 4px;
  padding: 0.2rem 0.8rem;
}

a.tab-sectores-pdis {
  background-color: var(--color_fondo);
  color: var(--color_fondo_secundario);
  font-weight: bold;
}

.tab-sectores-pdis.active {
  background-color: var(--color_fondo_secundario);
  color: var(--color_fondo);
}

.info-esquema {
  position: absolute;
  width: 30vw;
  margin: calc(10vh + 5px) 35vw 0 35vw;
}

.info-esquema--sin-perfil {
  margin: 5px 35vw;
}

.info-esquema .card {
  border-radius: 0 0 .25rem .25rem;
}

.info-esquema .card-header {
  padding: 5px 10px;
  border-bottom: 0;
  border-radius: 0 0 .25rem .25rem;
}

.info-esquema__dist{
  float: right;
}

.info-esquema h4 {
  font-size: 1rem;
  font-weight: 600;
  opacity: 1;
  margin-bottom: .2rem;
}

.info-esquema h6 {
  font-size: .8rem;
  opacity: 1;
}

.info-esquema__sector-icono > svg{
  width: .7rem;
  height: .7rem;
}

.info-esquema__background{
  position: absolute;
  height: 100%;
  opacity: 1;
  border: none;
}

@media (max-width: 768px) {
  .info-esquema {
    width: 90vw;
    margin: calc(15vh + 5px) 5vw 0 5vw;
  }
}

.heightgraph {
  position: absolute;
  margin: 0 35vw;
}

.leaflet-right .leaflet-control.heightgraph {
  margin-top: 15px;
}

.heightgraph-container {
  background-color: rgba(26, 26, 26, .5);
  color: #fff;
  border-radius: .25em .25em 0 0;
}

.heightgraph-container .grid {
  display: none;
}

.heightgraph-container > text {
  display: none;
}

.heightgraph-container .tick text {
  fill: #fff;
}

.heightgraph-container .domain {
  stroke: #fff;
  stroke-width: 3px;
}

.heightgraph-container .lineSelection {
  display: none;
}

.heightgraph-container .legend {
  display: none;
}

.heightgraph-container .legend-hover {
  display: none;
}

.heightgraph-container rect {
  pointer-events: none !important;
}

@media (max-width: 768px) {
  .heightgraph {
    margin-bottom: 60px;
    margin: 0 5vw;
  }
}

.info-pdi__container {
  width: 30vw;
  height: 40px;
  background-color: var(--color_btn_shadow_secundario);
  border-radius: .25rem;
  transition: background-color 500ms ease-out, color 500ms ease-out;
  color: var(--color-secundario);
}

.info-pdi__container--cambio {
  background-color: var(--color-secundario);
  color: var(--color-principal);
  transition: background-color 100ms ease-out, color 100ms ease-out;
}

.info-pdi__nombre {
  font-size: 1.3em;
}

.info-pdis__servicio {
  width: 25px;
  height: 25px;
}

.info-pdis__servicio > i {
  font-size: 1rem;
}

@media (max-width: 768px) {
  .info-pdi__container {
    width: 90vw;
  }
}

.pdi-focused {
  width: 280px;
  background-color: var(--color-principal);
  color: var(--color-secundario);
}

.pdi-focused__servicio {
  width: 30px;
  height: 30px;
  font-size: 18px;
}

.pdi-focused__descripcion {
  text-transform: initial;
  font-size: 1.1em;
}

.pdi-focused__btn {
  background-color: var(--color-secundario);
  color: var(--color-principal);
}

.pdi-focused__btn:hover,
.pdi-focused__btn:active {
  color: var(--color-principal);
}

a.pdi-focused__btn {
  color: var(--color-principal);
}

.pdi-focused__cerrar {
  right: .75rem;
  top: .5rem;
  font-size: 1.4em;
  background-color: transparent;
  border: none;
}

.pdi-focused__cerrar:hover,
.pdi-focused__cerrar:active,
.pdi-focused__cerrar:focus {
  outline: none;
}

.pdi-focused__btn--footer {
  font-size: 1.2em;
}

.pdi-focused__color {
  width: 30px;
  height: 8px;
}

.pdi-focused__avatar-user {
  width: 3em;
  max-height: 3em;
  object-fit: cover;
  border: 2px solid var(--color-secundario);
  background-color: #FFFFFF;
}

.pdi-focused__user-item {
  text-transform: none;
}



/* Transformamos un control posicionado en bottomleft en bottom */
.leaflet-left.leaflet-bottom {
    right: 0;
    display: flex;
    background: rgba(255, 255, 255, .0);
    transition: all 0.7s linear;
}
.leaflet-left.leaflet-bottom .leaflet-control {
    width: 100%;
    text-align: center;
    /*
    height: 100%;
    display: grid;
    grid-template-rows: 1fr auto;
    */
    display: flex;
    align-self: flex-end;
    flex-direction: column;
    margin: 0;
}


.control-expanded {
    /*
    right: 0;
    top: 0;
    */
    height: 100%;
    background: rgba(255, 255, 255, .8);
}

.leaflet-left.leaflet-bottom.control-expanded {
    /*
    right: 0;
    top: 0;
    */
    height: 100%;
    background: rgba(255, 255, 255, .8);
}

.control-expanded .leaflet-control {
    height: 100%;
    position: absolute;
}

.mapa-modal__wrapper {

}
.control-expanded .mapa-modal__wrapper {
    display: flex;
    /* padding: 2em; */
    /*
    align-self: center;
    justify-items: center;
    align-items: center;
    flex-grow: 1;
    */
    flex: 1 1;
    min-height: 0px;  /* firefox */
}

.mapa-botones {
    display: inline-block;
    align-self: flex-end;
    flex: none;
    width: 100%;
}
.control-expanded .mapa-botones {

}
    /*
    .control-expanded .mapa-modal__wrapper {
        display: flex;
        align-self: center;
    }
    */

.oculto {
    display: none;
}





/* Transformamos un control posicionado en topright en top */
.leaflet-right.leaflet-top {
    right: 0;
    display: flex;
    left: 0;
    /* height: 100%; */
}
.leaflet-right.leaflet-top .leaflet-control {
    display: grid;
    grid-template-columns: auto 1fr auto;
    width: 100%;
}

/* moveos o control de zoom...*/
.leaflet-top .leaflet-control.leaflet-control-zoom {
    margin-top: 60px;
}

.leaflet-container .mapa-paginador__left, .leaflet-container .mapa-paginador__right,
.leaflet-container .mapa-paginador__left:hover, .leaflet-container .mapa-paginador__right:hover,
.leaflet-container .mapa-paginador__left:active, .leaflet-container .mapa-paginador__right:active,
.leaflet-container .mapa-paginador__left:focus, .leaflet-container .mapa-paginador__right:focus {
    border: none;
    background: none;
    color: var(--color-principal);
    font-size: 60px;
    font-weight: bold;
    line-height: 30px;
    text-decoration: none;
    -webkit-text-stroke-width: 1.4px;
    -webkit-text-stroke-color: var(--color-secundario);
    margin-top: 5px;
    /* text-shadow: 0 0 5px black; /* para ue se vexan un pouquiño mellor */
}

.leaflet-container .mapa-paginador__left{
    margin-left: 10px;
}

.mapa-paginador__paginas {
    text-align: center;
    list-style: none;
    padding-left: 0px;
    margin-top: 5px;
}
.mapa-paginador__pagina-li {
    display: inline-block;

}
.mapa-paginador__pagina {
    border-radius: 100%;
    display: inline-block;
    overflow: hidden;
    margin: 5px;
    width: 15px;
    height: 15px;
    background: var(--color-secundario);
    color: var(--color-secundario) !important;
    /* box-shadow: 0 0 5px black; /* para ue se vexan un pouquiño mellor */
    border: 2px solid var(--color-terciario);
}
.mapa-paginador__pagina:before {
    content: " ";
}


.mapa-paginador__pagina--activa {
    width: 25px;
    height: 25px;
    color: var(--color-principal) !important;
    background: var(--color-principal);
    margin-bottom: 0;
    border: 2px solid var(--color-secundario);
}

.mapa-fullscreen > .leaflet-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}


/** Botons do visor **/

.boton-visor {
  font-family: 'Barlow Semi Condensed', Roboto, Arial, 'sans-serif';
  width: 57px;
  height: 57px;
  display: inline-block;
  background-color: var(--color-principal);
  border-radius: 5px !important;
  text-align: center;
  margin: 15px;
  overflow: hidden;
  padding: 0;
  transition: background-color 0.3s ease;
}

.boton-visor:hover,
.boton-visor:active,
.boton-visor:focus{
  background-color: var(--color-terciario);
  border: 1px solid var(--color-secundario);
}

.boton-visor a {
  color: var(--color_fondo)
}
.boton-visor button,
.boton-visor > a {
  color: var(--color_fondo);
  background-color: transparent;
  border: none;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.boton-visor .fas {
  margin-bottom: 5px;
  display: block;
}

.boton-visor > a:hover,
.boton-visor > a:active,
.boton-visor > a:focus{
  text-decoration: none;
}

.boton-visor.boton-destacado{
  background-color: var(--color-secundario);
  color: var(--color-principal);
  border: 1px solid var(--color-principal);
  transition: all 0.3s ease;
}

.boton-visor.boton-destacado:hover,
.boton-visor.boton-destacado:active,
.boton-visor.boton-destacado:focus{
  background-color: var(--color-terciario);
  color: var(--color-secundario);
  border: 1px solid var(--color-principal);
}

.boton-visor.boton-destacado:hover a,
.boton-visor.boton-destacado:active a,
.boton-visor.boton-destacado:focus a{
  color: var(--color-secundario);
}


.boton-visor.boton-visor--activo {
  background-color: var(--color-terciario);
  color: var(--color-secundario);
  border: 1px solid var(--color-principal);
}

.boton-visor.boton-destacado a{
  color: var(--color-principal);
}

.boton-visor .btn__texto {
  text-transform: uppercase;
  font-size: 10px;
  line-height: 1;
  display: block;
}

.boton-visor--small {
  width: 52px;
  height: 52px;
  margin: 6px 6px;
  display: table-cell;
  vertical-align: middle;
  font-size: 1.15rem;
  padding: 2px;
}

.boton-visor--small .btn__texto {
  text-transform: uppercase;
  font-size:  0.5em;
  line-height: 1;
}

@media (max-width: 350px) {
  .boton-visor--small {
    width: 45px;
    height: 45px;
    margin: 5px 5px;
  }
  .boton-visor--small .btn__texto {
    font-size:  0.4em;
  }
}

@media (min-width: 1024px) {
  .boton-visor--small {
    width: 80px;
    height: 80px;
    font-size: 1.7rem;
  }
}

@media (min-width: 1900px) {
  .boton-visor--small {
    width: 95px;
    height: 95px;
    font-size: 2rem;
    padding: 5px;
  }
}

.boton-visor__group {
  padding: 0;
  position: center;
}

ul.boton-visor__group {
  margin-bottom: 0.8rem;
}

.sub-heramientas ul.boton-visor__group {
  margin-bottom: 0;
}

.sub-heramientas .boton-visor--small {
  margin-bottom: 0;
}

.sub-heramientas .boton-visor{
  background-color: var(--color-secundario);
  color: var(--color-principal);
}

.sub-heramientas .boton-visor:hover{
  background-color: var(--color-terciario);
}

.sub-heramientas .boton-visor button,
.sub-heramientas .boton-visor a{
  color: var(--color-principal);
}

.sub-heramientas .boton-visor:hover button,
.sub-heramientas .boton-visor:hover a{
  color: var(--color-secundario);
}

.boton-visor .fa-stack {
  font-size: 0.6em;
  margin-top: -0.5em;
}

.boton-visor .fa-stack-2x {
  opacity: 0.7;
}

.boton-visor .boton-visor__icon-texto {
  margin-top: -.2em;
  font-size: .9em;
  text-transform: none;
  font-style: normal;
  font-weight: bold;
}

/** /Botons do visor **/

.obras__cerrar {
  right: 1rem;
  top: 1rem;
}

.obras__img {
  width: 25%;
}

@media (max-width: 577px) {
  .obras__img {
    width: 75%;
  }
}

.mapa-fullscreen > .leaflet-container {
  background: transparent;
  top: 10%;
  height: 90%;
}

.mapa-fullscreen {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(/static/media/fondo-mapa-captura.17e0d14e.svg) repeat;
  background-size: cover;
}

.mapa-fullscreen .leaflet-control-container {
  display: none;
}



.visor__layout--captura .BrainhubCarousel__container {
  display: none;
}
.visor__layout--captura .perfil__datos {
  font-size: 18px;
}
.visor__layout--captura .perfil__sizer {
  margin-top: 40px;
}

.rec-list-canal__row {
  padding: 15px 0;
  color: var(--color_texto_secundario);
}

.rec-list-canal__row:hover {
  text-decoration: none;
  color: var(--color_texto_secundario_hover);
}

.rec-list-canal__row-impar {
  background-color: var(--color-terciario);
}

.rec-list-canal__icon {
  margin-right: 5px;
  display: block;
  width: 35px;
}

.rec-list-canal__captura {
  max-width: 120px;
}

.rec-list-canal__captura > img {
  max-width: 100%;
}

.rec-list-canal__cont-sectores {
  text-align: right;
}

.rec-list-canal__lista-sector {
  list-style: none;
  text-align: left;
  margin: 0;
  padding: 0;
}

.rec-list-canal__titulo-sector {
  padding: 5px 10px;
}

.rec-list-canal__dist-sector{
  float: right;
}

.recorrido-list__proyecto-nombre {
  background-color: rgba(255, 255 ,255 , 0.7);
  padding: 5px 15px 0 15px;
  font-size: .9em;
  line-height: 1em;
}

.recorrido-list__recorrido-nombre--canal{
  line-height: 1em;
  padding: 0 15px 5px 15px;
}

.card-body {
  padding: 0.5em 1em;
}

.card-lista {
  margin-bottom: 10px;
  line-height: 1em;
}

.card-lista__link {
  color: var(--color_texto_secundario);
}

.card-lista__link:hover {
  text-decoration: none;
  color: var(--color_texto_secundario_hover);
}

.card-lista__imagen {
  width: 100%;
  margin-bottom: 15px;
}

.card-lista > .card-title {
  text-align: left;
  margin: 15px;
  background-color: var(--color-secundario);
  color: #ffffff;
  padding: 2px 10px;
}

.card-lista > .card-footer {
  font-size: 14px;
  text-transform: initial
}

.card-lista__fila {
  color: var(--color_texto_secundario);
  margin-bottom: 0.5em;
  padding-bottom: 0.5em;
  border-bottom: 1px dashed var(--color_texto_secundario);
}

.card-lista__datos > div:last-child .card-lista__fila {
  border-bottom: none;
}

.card-lista__fila:hover {
  text-decoration: none;
  color: var(--color_texto_secundario_hover);
}

.card-lista__datos {
  margin-bottom: 0;
}

.card-lista__distancia {
  text-transform: initial;
}

.card-lista .rec-list-canal__icon {
  width: 30px;
  height: 30px;
  display: table;
}

.rec-list-canal__nombre-proyecto {
  font-size: .8em;
}

:root {
  --canal-cabecera__tamaño: 250px;
}

.canal-cabecera {
  height: calc(var(--canal-cabecera__tamaño) + 4vw);
  width: 100%;
  z-index: 2;
  position: relative;
}

.canal-cabecera__imagen {
  height: calc(var(--canal-cabecera__tamaño) + 4vw);
  position: absolute;
}

.canal-cabecera__fondo {
  filter: blur(8px);
  -webkit-filter: blur(8px);
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.canal-subcabecera {
  width: 100%;
  background-color: var(--color_fondo);
  z-index: 10;
  position: relative;
  padding: 20px 60px;
  display: inline-flex;
}

.canal-subcabecera__titulo {
  margin-left: 25px;
  display: inline-block;
  color: var(--color_texto);
}

.canal-subcabecera__titulo h2{
  margin: 0;
}

.canal-subcabecera__avatar {
  width: 7vw;
  height: 7vw;
  max-height: 122px;
  max-width: 122px;
  min-height: 80px;
  min-width: 80px;
  object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color_texto);
  border: 7px solid #fff;
}

.canal-subcabecera__avatar > img {
  max-width: 165px;
  max-height: 165px;
  display: block;
  object-fit: cover;
  overflow: hidden;
}

.canal-tabs-container {
  width: 100%;
}

.canal-tabs-container > nav {
  justify-content: center;
  background-color: var(--color-secundario);
}

.canal-tabs-container > nav > .nav-link{
  color: var(--color_texto);
  border: none;
}

.canal-tabs-container > nav > .nav-link.active{
  background-color: var(--color-principal);
}

.canal-tabs__container-tab {
  margin-top: 15px;
}

.canal-tabs__recorrido-destacado {
  width: 100%;
  height: 400px;
}

@media (max-width: 640px) {
  .canal-subcabecera {
    padding: 20px 20px;
  }

  .canal-subcabecera__titulo {
    margin-left: 0;
    display: block;
    width: 100%;
    margin-top: 30px;
  }

  .canal-subcabecera__avatar {
    width: 80px;
    height: 80px;
    display: flex;
    position: absolute;
    top: -40px;
  }
}

/** Pure CSS Masonry Layout **/

.masonry {
  -webkit-columns: 300px;
          columns: 300px;
  grid-column-gap: 25px;
  -webkit-column-gap: 25px;
          column-gap: 25px;
}

.masonry .article {
  margin-bottom: 25px;
  page-break-inside: avoid;
  display: grid;
}

/** /Pure CSS Masonry Layout **/

.lista-subcabecera {
  width: 100%;
  height: auto;
  background-color: var(--color_fondo);
  z-index: 10;
  position: relative;
  padding: 20px 60px;
  display: inline-flex;
}

.lista-subcabecera__titulo {
  margin-left: 20px;
  display: inline-block;
  color: var(--color_texto);
  line-height: 1em;
}

.lista-subcabecera__titulo h2{
  margin-bottom: 0;
}

.lista-subcabecera__avatar > img {
  width: 7vw;
  height: 7vw;
  max-height: 140px;
  max-width: 140px;
  min-height: 75px;
  min-width: 75px;
  object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color_texto);
  border: 5px solid #fff;
}

.lista-subcabecera__link {
  color: var(--color_texto);
}

.lista-subcabecera__link:hover {
  text-decoration: none;
  color: var(--color_texto_hover);
}

.lista-contenido {
  width: 100%;
  max-width: 100%;
  position: relative;
  z-index: 1;
}

.lista__col-mapa {
  padding: 0;
}

.lista-contenido .container-map-portada {
  margin-top: 0;
  height: calc(100vh - 30vh)
}

.lista__col-listado{
  height: calc(100vh - 30vh);
  overflow: auto;
}

.lista-subcabecera .btn {
  color: var(--color-principal);
  position: absolute;
  right: 0.5em;
  top: 0.5em;
}

.lista-subcabecera .btn.perfil__btn-share{
  right: 2.5em;
}

.lista__col-mapa img.lista__logo {
  z-index: 400;
  width: 60px;
  position: relative;
  left: 10px;
  bottom: 60px;
  border-radius: 5px;
  padding: 3px 6px;
}

@media (max-width: 640px) {
  .lista-subcabecera {
    padding: 14px 14px;
  }

  .lista-subcabecera__titulo {
    margin-left: 0;
    display: block;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .lista-subcabecera__avatar {
    width: 75px;
    height: 75px;
    display: flex;
    position: absolute;
    bottom: -50px;
    right: 20px;
  }
}

@media (max-width: 768px) {
  .lista-subcabecera__titulo {
    font-size: 2.5vh;
  }
  .lista-subcabecera__titulo h2 {
    font-size: 4vh;
  }

  .lista-contenido .container-map-portada {
    height: 30vh;
  }
  .lista__col-mapa {
    height: 30vh;
    overflow: hidden;
  }
  .lista__col-listado{
    overflow: auto;
    height: 55vh;
  }
  .lista-subcabecera {
    height: 15vh;
  }
}

.pedido__form small {
  text-transform: initial;
}

.ficha-contacto {
  max-width: 500px;
  line-height: 1.2em;
  border-bottom: 1px dashed var(--color-gris);
}

.contacto-add .row:last-child .ficha-contacto{
  border-bottom: none;
}

.ficha-contacto__avatar {
  width: 4em;
  max-height: 4em;
  object-fit: cover;
  border: 3px solid var(--color-secundario);
}

@media (max-width: 500px) {
  .ficha-contacto {
    max-width: 100%;
  }
}

.contactos__btn-mas {
  bottom: 2rem;
  right: 2rem;
  height: 70px;
  width: 70px;
}

.contactos h3 {
  border-bottom-width: 2px;
  border-bottom-style: dotted;
}

.contactos ul {
  padding: 0;
  list-style: none;
}

.background-dark .fa-circle {
  color: var(--color-gris);
}

.contactos button {
  border: 1px solid var(--color-principal);
}

.contacto_nombre {
  font-size: 1.3em;
}

.modal-descripcion__resumen {
  background-color: var(--color-principal);
  color: var(--color-secundario);
}

.modal-descripcion__lista-ubicaciones > li {
  color: var(--color-principal);
  background-color: var(--color-secundario);
  border: 1px dashed var(--color-principal);
}

.modal-descripcion__icono-bloques {
  color: var(--color-secundario);
  background-color: var(--color-principal);
  font-size: 24px;
  width: 45px;
  height: 45px;
}

.modal-descripcion__descripcion {
  white-space: pre-line;
}

.modal-usuarios__lista > li {
  background-color: var(--color-secundario);
  color: #fff;
  border-bottom: 1px dashed #fff;
}

.modal-usuarios__avatar {
  width: 4rem;
  max-height: 4rem;
  background-color: #fff;
  object-fit: cover;
}

.modal-usuarios__icon .fa-inverse {
  color: var(--color-secundario);
}

.moda-usuarios__toggle {
  cursor: pointer;
}

.modal-usuarios__lista--elementos > li {
  border-bottom: none;
  cursor: pointer;
}

