.grafico__area {
  stroke-linejoin: round;
  fill: #FFCC00;
  stroke-width: 1px;
}

.grafico__eje line {
  stroke: #4F4F4F;
}
.grafico__eje path {
  stroke: var(--color-terciario);
  stroke-width: 2px;
}
.grafico__eje text {
  fill: var(--color-terciario);
}
.grafico__eje .tick line {
  stroke: var(--color-terciario);
}
.grafico__eje .tick:first-of-type line {
  stroke: #D0D0D0;
}
.grafico__eje .tick:last-of-type line {
  stroke: #D0D0D0;
}

.grafico__ejex > path,
.grafico__ejey > path,
.grafico__ejex > .tick > line,
.grafico__ejey > .tick > line{
  stroke: var(--color-terciario);
}

.grafico__ejey > .tick > text,
.grafico__ejex > .tick > text{
  fill: var(--color-terciario);
  text-transform: none;
  stroke: var(--color-terciario);
  stroke-width: .5;
}

.grafico__container {
  position: relative;
}

.pdi__linea {
  stroke-width: 1;
  fill: var(--color-terciario);
  stroke: var(--color-terciario);
}
.pdi__circulo {
  stroke-width: 0;
  fill: black;
}
.pdi__titulo {
  fill: #000000;
  font-size: 12px;
  font-family: Arial;
  text-transform: uppercase;
}
.pdi__altura {
  fill: black;
  font-size: 16px;
  font-weight: bold;
  font-family: Arial;

}
.pdi__recuadro {
  stroke-width: 1;
  /*stroke: red;*/
  fill: transparent;
}

.pdi__icono {
  width: 30px;
  height: 30px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 55px;
  transition: width .1s, height .1s, margin-left .1s, border-radius .1s;
}

.pdi__icono--seleccionado {
  width: 45px;
  height: 45px;
  border-radius: .5em;
  margin-left: 47.5px;
  transition: width .1s, height .1s, margin-left .1s, border-radius .1s;
}

.pdi__icono i {
  font-size: 1rem;
  transition: font-size .1s;
}

.pdi__icono--seleccionado i {
  font-size: 1.5rem;
  transition: font-size .1s;
}

.pdi__icono--pk i {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.pdi__foreign, .pdi__foreign > div {
  pointer-events: none;
}

.pdi__foreign > div > div {
  pointer-events: all;
  cursor: pointer;
}
