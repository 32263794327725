@import "../../variables.css";


.top-slider {
    background: var(--color_fondo_secundario);
    color: var(--color_fondo);
    height: var(--top-bar-height);
    display: grid;
    grid-template-columns: var(--top-bar-height) auto var(--top-bar-height);
    font-size: 22px;
    line-height: var(--top-bar-height);
    text-align: center;
    font-weight: bold;
}
.top-slider__left {
    grid-row: 1;
    grid-column: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.top-slider__button {
  background: none;
  border: none;
}

.top-slider__button:hover,
.top-slider__button:active,
.top-slider__button:focus {
  outline: none;
}

.top-slider__center {
    color: var(--color_fondo);
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    grid-row: 1;
    grid-column: 2;
    display: grid;
    grid-template-rows: calc(var(--top-bar-height) / 2) calc(var(--top-bar-height) / 2);
}
.top-slider__mapa {
    line-height: calc(var(--top-bar-height) / 2);
    font-size: 16px;
}
.top-slider__dots {
    list-style: none;
    margin: 0;
    padding: 0;
}
.top-slider__dots > li {
    display: inline-block;
    margin-left: .25em;
    margin-right: .25em;
    height: calc(var(--top-bar-height) / 2);
}

.top-slider__dot {
    height: 10px;
    width: 10px;
    padding: 0;
    border-radius: 100%;
    border: none;
    text-shadow: none;
    display: block;
    float: left;
    background: var(--color_fondo);
    opacity: .5;
}

.top-slider__dot--current {
    height: 18px;
    width: 18px;
    margin-top: -5px;
    opacity: 1;
}

.top-slider__right {
    grid-row: 1;
    grid-column: 3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.top-slider .fa-chevron-left,
.top-slider .fa-chevron-right{
    font-size: 1.8em;
}
