.ais-Hits {
  font-family: var(--font-family);
}

.ais-Hits-list {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 0;
    padding: 0;
    margin: 1em 0 0;
}

.ais-Hits-item {
    display: block;
    border: none;
    box-shadow: none;
    margin: 0;
    padding: 1.4em 1.4em;
    width: 100%;
}
.ais-Hits-item:nth-child(even) {
    background: #ffefc9;
}
.ais-Hits-item a,
.ais-Hits-item a:hover,
.ais-Hits-item a:focus,
.ais-Hits-item a:active {
    color: var(--color_fondo);
    text-decoration: none;
}

.ais-Hits a .hit-image {
    max-width: 25%;
}

.ais-Hits a .hit-data {
    max-width: 75%;
    padding-left: 15px;
}

.ais-Hits a img.proyecto-captura {
    object-fit: cover;
    width: 135px;
    height: 135px;
    margin: 0 auto;
    font-size: 10px;
    background-color: #fff;
}

.ais-Hits a img.proyecto-logo {
  width: 55px;
  height: 55px;
  position: absolute;
  margin: 10px;
}

.ais-Hits {
    font-weight: 300;
    text-transform: uppercase;
}

.ais-Hits .hit-name {
    font-size: 1.5em;
    text-transform: uppercase;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1em;
    margin-top: -5px;
}

.ais-Hits .hit-description {
    line-height: 1.1em;
    text-transform: none;
    width: 100%;
    display: table;
}

.ais-Hits .hit-description-text {
    overflow: hidden;
    line-height: 2.2ch;
    text-transform: initial;
}

.ais-Hits .badge-container {
    display: inline;
    margin-right: 3px;
}

.ais-Hits .hit-date p{
    margin-bottom: 0.3rem;
}

.ais-Hits .hit-date strong{
    font-weight: bold;
}

.ais-Hits .badge {
    font-weight: 300;
    text-transform: uppercase;
    margin-top: 7px;
    font-size: 0.9em;
}

.ais-CurrentRefinements-item{
    background-color: var(--color-secundario);
}

.ais-RefinementList-label {
    margin-bottom: 0.3em;
}

.ais-RefinementList-labelText {
    padding: 0 0.4em;
}

.ais-ClearRefinements-button {
    width: 100%;
    background-color: var(--color-gris-oscuro);
    font-size: 1.2em;
    padding: 0.5em 0.1em
}

.ais-ClearRefinements-button:hover{
    background-color: var(--color-secundario);
}

.ais-CurrentRefinements-label {
    font-weight: bold;
}

.ais-CurrentRefinements-label::after {
    content: ":";
}

.ais-CurrentRefinements-category {
    margin-left: 0.5em;
}

.ais-RefinementList-count {
    background-color: var(--color-secundario);
    color: var(--color-terciario);
}

.filtros .close-window {
    font-size: 26px;
}

.herramientas-busqueda .btn{
    font-size: 1.4em;
}

.hit-info {
  padding: .4rem 0;
  display: flex;
  align-items: center;
}

.hit-deporte {
  width: 30px;
  background-color: var(--color-secundario);
  stroke: var(--color-principal);
  fill: var(--color-principal);
  stroke-width: 0;
}

.hit-deporte > svg path {
  fill: var(--color-principal);
  stroke-width: 0;
}

.hit__btn-mas {
  background-color: transparent;
  border: 0;
  font-weight: bold;
}

.hit__btn-mas:focus,
.hit__btn-mas:active{
  border: 0;
  outline: none;
}

.hit__check {
  height: 25px;
  vertical-align: baseline;
}

@media (max-width: 991px) {
    .ais-Hits a img.proyecto-captura {
        width: 125px;
        height: 125px;
    }
}

@media (max-width: 767px) {
    .ais-Hits a img.proyecto-captura {
        width: 100px;
        height: 100px;
    }
}
@media (max-width: 575px) {

    .ais-Hits a .hit-image {
      max-width: 30vw;
    }

    .ais-Hits a img.proyecto-logo {
      width: 9vw;
      height: 9vw;
      margin: 1.5vw;
    }

    .ais-Hits a .hit-data {
        font-size: 0.8em;
        max-width: 70vw;
    }

    .ais-Hits a img.proyecto-captura {
        width: 25vw;
        height: 25vw;
    }

    .ais-Hits-item{
        padding: 10px;
    }
}

