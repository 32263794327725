.slider-pdis__slide {
  min-width: 150px;
  width: calc(100% - 5px);
  height: 140px;
  background-color: var(--color_fondo_seleccionado);
  margin-bottom: 1em;
  overflow: hidden;
}

.slider-pdis__slidev--seleccionado {
  background-color: var(--color-principal);
  color: var(--color-secundario)
}

.slider-pdis__list-serv {
  display: flex;
  width: 100%;
  justify-content: center;
}

.slider-pdis__servicio {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-pdis__servicio--mas{
  background-color: var(--color-secundario);
  color: var(--color-principal);
  cursor: pointer;
}

.slider-pdis__distancias {
  font-size: .8rem;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: none;
  white-space: nowrap;
  max-width: 100%;
}

.slider-pdis__descripcion {
  font-size: .75rem;
  line-height: 1.2em;
  text-overflow: ellipsis;
  text-transform: none;
}

.slider-pdis__btn-mas {
  font-size: 0.8em;
}

.slider-pdis__slidev--seleccionado .slider-pdis__btn-mas {
  background-color: var(--color_fondo_seleccionado);
  color: #fff;
}
